import store from '../store'
export default {
  auth: {
    inserted: (el, binding) => {
      const authModules = store.state.user.perms
      if (binding.value && authModules.indexOf(binding.value) === -1) {
        if (binding.arg === 'remove') {
          el.remove()
        } else {
          el.className += ' is-disabled '
          el.setAttribute('disabled', 'disabled')
        }
      }
    },
  },
}
