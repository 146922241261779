/*
 *选择框数据
 */

/**
 *
 *获取量程单位
 * @export
 * @param {string} [type='All']
 * @returns
 */
export const getRangeUnit = (type = 'All') => {
  // 温度单位
  let tUnit = [
    {
      label: '温度',
      options: [
        { label: 'K', value: 'K' },
        { label: '°C', value: '°C' },
        { label: '°F', value: '°F' },
        { label: '°R', value: '°R' },
        { label: '°Re', value: '°Re' },
        { label: 'mK', value: 'mK' },
      ],
    },
  ]
  //压力单位
  let pUnit = [
    {
      label: '压力',
      options: [
        { label: 'Pa', value: 'Pa' },
        { label: 'kPa', value: 'kPa' },
        { label: 'MPa', value: 'MPa' },
        { label: 'bar', value: 'bar' },
        { label: 'mbar', value: 'mbar' },
        { label: 'inHg', value: 'inHg' },
        { label: 'mmHg', value: 'mmHg' },
        { label: 'inH2O', value: 'inH2O' },
        { label: 'Torr', value: 'Torr' },
        { label: 'atm', value: 'atm' },
        { label: 'psi', value: 'psi' },
        { label: 'psia', value: 'psia' },
      ],
    },
  ]
  //电学单位
  let eUnit = [
    {
      label: '电学',
      options: [
        { label: 'A', value: 'A' },
        { label: 'mA', value: 'mA' },
        { label: 'µA', value: 'µA' },
        { label: 'V', value: 'V' },
        { label: 'mV', value: 'mV' },
        { label: 'µV', value: 'µV' },
        { label: 'Ω', value: 'Ω' },
        { label: 'kΩ', value: 'kΩ' },
        { label: 'MΩ', value: 'MΩ' },
        { label: 'Hz', value: 'Hz' },
        { label: 'kHz', value: 'kHz' },
        { label: 'MHz', value: 'MHz' },
        { label: 'GHz', value: 'GHz' },
      ],
    },
  ]
  //全部单位
  let allUnit = pUnit.concat(eUnit).concat(tUnit)
  let result = []
  switch (type) {
    case 'All':
      result = allUnit
      break
    case 'P':
      result = pUnit
      break
    case 'E':
      result = eUnit
      break
    case 'T':
      result = tUnit
      break
    default:
      result = allUnit
      break
  }
  return {
    code: 200,
    msg: null,
    data: result,
  }
}

/**
 *获取压力类型
 *
 * @export
 * @returns
 */
export const getpressureType = () => {
  return {
    code: 200,
    msg: null,
    data: [
      { label: '表压', value: 'G' },
      { label: '绝压', value: 'A' },
      { label: '差压', value: 'D' },
    ],
  }
}

/**
 *获取数据类型
 *
 * @export
 * @returns
 */
export const getDataType = () => {
  return {
    code: 200,
    msg: null,
    data: [
      { label: '压力', options: [{ label: '压力', value: 'P_P_M' }] },
      {
        label: '电学',
        options: [
          { label: '电流输出', value: 'E_EA_S' },
          { label: '电流测量', value: 'E_EA_M' },
          { label: '电压输出', value: 'E_EV_S' },
          { label: '电压测量', value: 'E_EV_M' },
          { label: '电阻输出', value: 'E_ER_S' },
          { label: '电阻测量', value: 'E_ER_M' },
          { label: '频率输出', value: 'E_EF_S' },
          { label: '频率测量', value: 'E_EF_M' },
        ],
      },
      {
        label: '温度',
        options: [
          { label: '温度输出', value: 'T_TT_S' },
          { label: '温度测量', value: 'T_TT_M' },
          { label: '热电偶输出', value: 'T_TTH_S' },
          { label: '热电偶测量', value: 'T_TTH_M' },
          { label: 'K偶输出', value: 'T_TCK_S' },
          { label: 'K偶测量', value: 'T_TCK_M' },
          { label: 'PT100_385输出', value: 'T_TPT100_S' },
          { label: 'PT100_385测量', value: 'T_TPT100_M' },
        ],
      },
    ],
  }
}

/**
 *获取数据类型
 *
 * @export
 * @returns
 */
export const getParentStdType = () => {
  return {
    code: 200,
    msg: null,
    data: [
      { label: '证书', value: 0 },
      { label: '标准器值', value: 1 },
    ],
  }
}

/**
 *获取不确定度值类型
 *
 * @export
 * @returns
 */
export const getUnCertValType = () => {
  return {
    code: 200,
    msg: null,
    data: [
      { label: '%', value: '%' },
      { label: 'ppm', value: 'ppm' },
    ],
  }
}

/**
 *
 *获取精度等级标准 单位
 * @export
 * @param {string} [type='All']
 * @returns
 */
export const getAccuracyGradeUnit = (type = 'All') => {
  //计量院标准单位
  let jlyUnit = [
    {
      label: '计量院',
      options: [
        { label: 'I等', value: 'I等' },

        { label: '0.005级', value: '0.005级' },
        { label: '0.01级', value: '0.01级' },
        { label: '0.02级', value: '0.02级' },
        { label: '0.05级', value: '0.05级' },
      ],
    },
  ]
  //计量室标准单位
  let jUnit = [
    {
      label: '', //'计量室',
      options: [
        { label: '一级标准', value: '一级标准' },
        { label: '二级标准', value: '二级标准' },
        { label: '三级标准', value: '三级标准' },
      ],
    },
  ]
  //生产标准单位
  let sUnit = [
    {
      label: '生产',
      options: [
        { label: 'AA', value: 'AA' },

        { label: '一等标准', value: '一等标准' },
        { label: '二等标准', value: '二等标准' },
      ],
    },
  ]
  //研发标准单位
  let yUnit = [
    {
      label: '研发',
      options: [
        { label: '一级标准', value: '一级标准' },
        { label: '二级标准', value: '二级标准' },
      ],
    },
  ]
  //全部单位
  let allUnit = jUnit.concat(sUnit).concat(yUnit).concat(jlyUnit)
  let result = []
  switch (type) {
    case 'All':
      result = allUnit
      break
    case 'JLY':
      result = jlyUnit
      break
    case 'JLS':
      result = jUnit
      break
    case 'SC':
      result = sUnit
      break
    case 'YF':
      result = yUnit
      break
    default:
      result = allUnit
      break
  }
  return {
    code: 200,
    msg: null,
    data: result,
  }
}

/**
 *获取动态列的缓存 key
 *
 * @export
 * @returns
 */
export const getColumnLocalStorageKey = (pageName, key) => {
  return 'columnConfig' + pageName + key
}

/**
 *获取不确定度值类型
 *
 * @export
 * @returns
 */
export const getOrganizationList = () => {
  return [
    { label: '北京康斯特仪表科技股份有限公司', value: '康斯特' },
    { label: '北京恒矩检测技术有限公司', value: '恒矩' },
  ]
}

/**
 *
 *获取温度标定步骤
 * @export
 * @param {string} [type='deviceType']
 * @returns
 */
export const getTCalStep = (type) => {
  let s = [
    { label: 'S1', value: 'S1' },
    { label: 'S2', value: 'S2' },
    { label: 'S3', value: 'S3' },
    { label: 'S4', value: 'S4' },
    { label: 'S5', value: 'S5' },
  ]
  let const660TL = [
    {
      label: '标定',
      options: [
        { label: 'L1', value: 'L1' },
        { label: 'H1', value: 'H1' },
        { label: 'H1A', value: 'H1A' },
        { label: 'L2', value: 'L2' },
        { label: 'H2', value: 'H2' },
        { label: 'L3', value: 'L3' },
        { label: 'H3', value: 'H3' },
        { label: 'L4', value: 'L4' },
        { label: 'H4', value: 'H4' },
        { label: 'L5', value: 'L5' },
        { label: 'H5', value: 'H5' },
        { label: 'L6', value: 'L6' },
        { label: 'H6', value: 'H6' },
        { label: 'H6A', value: 'H6A' },
      ],
    },
    {
      label: '自校准',
      options: s,
    },
    {
      label: '出数',
      options: [
        { label: 'Log1(0mm)', value: 'Log1(0mm)' },
        { label: 'Log1(20mm)', value: 'Log1(20mm)' },
        { label: 'Log1(40mm)', value: 'Log1(40mm)' },
        { label: 'Log1(60mm)', value: 'Log1(60mm)' },
        { label: 'Log2(0mm)', value: 'Log2(0mm)' },
        { label: 'Log3(0mm)', value: 'Log3(0mm)' },
        { label: 'Log3(20mm)', value: 'Log3(20mm)' },
        { label: 'Log3(40mm)', value: 'Log3(40mm)' },
        { label: 'Log3(60mm)', value: 'Log3(60mm)' },
        { label: 'Log4(0mm)', value: 'Log4(0mm)' },
        { label: 'Log5(0mm)', value: 'Log5(0mm)' },
        { label: 'Log5(20mm)', value: 'Log5(20mm)' },
        { label: 'Log5(40mm)', value: 'Log5(40mm)' },
        { label: 'Log5(60mm)', value: 'Log5(60mm)' },
      ],
    },
    {
      label: '极限低温',
      options: [{ label: 'Limit', value: 'Limit' }],
    },
  ]

  let const660TH = [
    {
      label: '标定',
      options: [
        { label: 'L1', value: 'L1' },
        { label: 'H1', value: 'H1' },
        { label: 'H1A', value: 'H1A' },
        { label: 'L2', value: 'L2' },
        { label: 'H2', value: 'H2' },
        { label: 'L3', value: 'L3' },
        { label: 'H3', value: 'H3' },
        { label: 'L4', value: 'L4' },
        { label: 'H4', value: 'H4' },
        { label: 'L5', value: 'L5' },
        { label: 'H5', value: 'H5' },
        { label: 'L6', value: 'L6' },
        { label: 'H6', value: 'H6' },
        { label: 'L7', value: 'L7' },
        { label: 'H7', value: 'H7' },
        { label: 'H7A', value: 'H7A' },
      ],
    },
    {
      label: '自校准',
      options: s,
    },
    {
      label: '出数',
      options: [
        { label: 'Log1(0mm)', value: 'Log1(0mm)' },
        { label: 'Log1(20mm)', value: 'Log1(20mm)' },
        { label: 'Log1(40mm)', value: 'Log1(40mm)' },
        { label: 'Log1(60mm)', value: 'Log1(60mm)' },
        { label: 'Log2(0mm)', value: 'Log2(0mm)' },
        { label: 'Log3(0mm)', value: 'Log3(0mm)' },
        { label: 'Log3(20mm)', value: 'Log3(20mm)' },
        { label: 'Log3(40mm)', value: 'Log3(40mm)' },
        { label: 'Log3(60mm)', value: 'Log3(60mm)' },
        { label: 'Log4(0mm)', value: 'Log4(0mm)' },
        { label: 'Log5(0mm)', value: 'Log5(0mm)' },
        { label: 'Log5(20mm)', value: 'Log5(20mm)' },
        { label: 'Log5(40mm)', value: 'Log5(40mm)' },
        { label: 'Log5(60mm)', value: 'Log5(60mm)' },
      ],
    },
  ]

  let const670L = [
    {
      label: '标定',
      options: [
        { label: 'L1', value: 'L1' },
        { label: 'H1', value: 'H1' },
        { label: 'H1A', value: 'H1A' },
        { label: 'L2', value: 'L2' },
        { label: 'H2', value: 'H2' },
        { label: 'L3', value: 'L3' },
        { label: 'H3', value: 'H3' },
        { label: 'L4', value: 'L4' },
        { label: 'H4', value: 'H4' },
        { label: 'L5', value: 'L5' },
        { label: 'H5', value: 'H5' },
        { label: 'H5A', value: 'H5A' },
      ],
    },
    {
      label: '自校准',
      options: s,
    },
    {
      label: '出数',
      options: [
        { label: 'Log1(0mm)', value: 'Log1(0mm)' },
        { label: 'Log1(20mm)', value: 'Log1(20mm)' },
        { label: 'Log1(40mm)', value: 'Log1(40mm)' },
        { label: 'Log1(60mm)', value: 'Log1(60mm)' },
        { label: 'Log1(80mm)', value: 'Log1(80mm)' },
        { label: 'Log2(0mm)', value: 'Log2(0mm)' },
        { label: 'Log2(20mm)', value: 'Log2(20mm)' },
        { label: 'Log2(40mm)', value: 'Log2(40mm)' },
        { label: 'Log2(60mm)', value: 'Log2(60mm)' },
        { label: 'Log2(80mm)', value: 'Log1(80mm)' },
        { label: 'Log3(0mm)', value: 'Log3(0mm)' },
        { label: 'Log4(0mm)', value: 'Log4(0mm)' },
        { label: 'Log5(0mm)', value: 'Log5(0mm)' },
        { label: 'Log5(20mm)', value: 'Log5(20mm)' },
        { label: 'Log5(40mm)', value: 'Log5(40mm)' },
        { label: 'Log5(60mm)', value: 'Log5(60mm)' },
        { label: 'Log5(80mm)', value: 'Log5(80mm)' },
      ],
    },
    {
      label: '极限低温',
      options: [{ label: 'Limit', value: 'Limit' }],
    },
  ]

  let const670H = [
    {
      label: '标定',
      options: [
        { label: 'L1', value: 'L1' },
        { label: 'H1', value: 'H1' },
        { label: 'H1A', value: 'H1A' },
        { label: 'L2', value: 'L2' },
        { label: 'H2', value: 'H2' },
        { label: 'L3', value: 'L3' },
        { label: 'H3', value: 'H3' },
        { label: 'L4', value: 'L4' },
        { label: 'H4', value: 'H4' },
        { label: 'L5', value: 'L5' },
        { label: 'H5', value: 'H5' },
        { label: 'L6', value: 'L6' },
        { label: 'H6', value: 'H6' },
        { label: 'L7', value: 'L7' },
        { label: 'H7', value: 'H7' },
        { label: 'H7A', value: 'H7A' },
      ],
    },
    {
      label: '自校准',
      options: [
        { label: 'S1', value: 'S1' },
        { label: 'S2', value: 'S2' },
        { label: 'S3', value: 'S3' },
        { label: 'S4', value: 'S4' },
        { label: 'S5', value: 'S5' },
        { label: 'S6', value: 'S6' },
        { label: 'S7', value: 'S7' },
      ],
    },
    {
      label: '出数',
      options: [
        { label: 'Log1(0mm)', value: 'Log1(0mm)' },
        { label: 'Log2(0mm)', value: 'Log2(0mm)' },
        { label: 'Log2(20mm)', value: 'Log2(20mm)' },
        { label: 'Log2(40mm)', value: 'Log2(40mm)' },
        { label: 'Log2(60mm)', value: 'Log2(60mm)' },
        { label: 'Log2(80mm)', value: 'Log2(80mm)' },
        { label: 'Log3(0mm)', value: 'Log3(0mm)' },
        { label: 'Log4(0mm)', value: 'Log4(0mm)' },
        { label: 'Log4(20mm)', value: 'Log4(20mm)' },
        { label: 'Log4(40mm)', value: 'Log4(40mm)' },
        { label: 'Log4(60mm)', value: 'Log4(60mm)' },
        { label: 'Log4(80mm)', value: 'Log4(80mm)' },
        { label: 'Log5(0mm)', value: 'Log5(0mm)' },
        { label: 'Log5(20mm)', value: 'Log5(20mm)' },
        { label: 'Log5(40mm)', value: 'Log5(40mm)' },
        { label: 'Log5(60mm)', value: 'Log5(60mm)' },
        { label: 'Log5(80mm)', value: 'Log5(80mm)' },
      ],
    },
  ]

  let const670U = [
    {
      label: '标定',
      options: [
        { label: 'C1-Step1', value: 'C1-Step1' },
        { label: 'C1-Step2', value: 'C1-Step2' },
        { label: 'C1', value: 'C1' },
        { label: 'C2-Step1', value: 'C2-Step1' },
        { label: 'C2-Step2', value: 'C2-Step2' },
        { label: 'C2', value: 'C2' },
        { label: 'C3-Step1', value: 'C3-Step1' },
        { label: 'C3-Step2', value: 'C3-Step2' },
        { label: 'C3', value: 'C3' },
        { label: 'C4-Step1', value: 'C4-Step1' },
        { label: 'C4-Step2', value: 'C4-Step2' },
        { label: 'C4', value: 'C4' },
        { label: 'C5-Step1', value: 'C5-Step1' },
        { label: 'C5-Step2', value: 'C5-Step2' },
        { label: 'C5', value: 'C5' },
        { label: 'C6-Step1', value: 'C6-Step1' },
        { label: 'C6-Step2', value: 'C6-Step2' },
        { label: 'C6', value: 'C6' },
      ],
    },
    {
      label: '出数',
      options: [
        { label: 'Log1(0mm)', value: 'Log1(0mm)' },
        { label: 'Log1(20mm)', value: 'Log1(20mm)' },
        { label: 'Log2(0mm)', value: 'Log2(0mm)' },
        { label: 'Log2(20mm)', value: 'Log2(20mm)' },
        { label: 'Log3(0mm)', value: 'Log3(0mm)' },
        { label: 'Log3(20mm)', value: 'Log3(20mm)' },
        { label: 'Log4(0mm)', value: 'Log4(0mm)' },
        { label: 'Log4(20mm)', value: 'Log4(20mm)' },
        { label: 'Log5(0mm)', value: 'Log5(0mm)' },
        { label: 'Log5(20mm)', value: 'Log5(20mm)' },
      ],
    },
  ]

  let result = []
  switch (type) {
    case 'ConST660TL':
      result = const660TL
      break
    case 'ConST660TH':
      result = const660TH
      break
    case 'ConST670L':
      result = const670L
      break
    case 'ConST670H':
      result = const670H
      break
    case 'ConST670U':
      result = const670U
      break
    default:
      result = const660TL
      break
  }
  return {
    code: 200,
    msg: null,
    data: result,
  }
}

/**
 *
 *获取温度测试项目
 * @export
 * @param {string} [type='deviceType']
 * @returns
 */
export const getTCalProject = (type) => {
  let twoLine = [
    { label: 'Tref', value: 'Tref' },
    { label: 'Tm', value: 'Tm' },
    { label: 'Tint0', value: 'Tint0' },
    { label: 'Tint1', value: 'Tint1' },
    { label: 'Tint2', value: 'Tint2' },
    { label: 'DT0', value: 'DT0' },
    { label: 'DTC', value: 'DTC' },
    { label: 'DTr', value: 'DTr' },
    { label: 'DTex', value: 'DTex' },
    { label: 'Tair', value: 'Tair' },
    { label: 'OutH', value: 'OutH' },
    { label: 'OutL', value: 'OutL' },
    { label: 'OF', value: 'OF' },
    { label: 'RPM', value: 'RPM' },
    { label: 'U', value: 'U' },
    { label: 'I', value: 'I' },
    { label: 'LogTime', value: 'LogTime' },
  ]
  let threeLine = [
    { label: 'Tref', value: 'Tref' },
    { label: 'Tm', value: 'Tm' },
    { label: 'Th', value: 'Th' },
    { label: 'Dtm', value: 'Dtm' },
    { label: 'Dth', value: 'Dth' },
    { label: 'Tint0', value: 'Tint0' },
    { label: 'Tint1', value: 'Tint1' },
    { label: 'Tint2', value: 'Tint2' },
    { label: 'Tair', value: 'Tair' },
    { label: 'OutH', value: 'OutH' },
    { label: 'OutM', value: 'OutM' },
    { label: 'OutL', value: 'OutL' },
    { label: 'OF', value: 'OF' },
    { label: 'RPM', value: 'RPM' },
    { label: 'OM', value: 'OM' },
    { label: 'U', value: 'U' },
    { label: 'I', value: 'I' },
    { label: 'LogTime', value: 'LogTime' },
  ]

  let result = []
  switch (type) {
    case 'ConST660TL':
      result = twoLine
      break
    case 'ConST660TH':
      result = twoLine
      break
    case 'ConST670L':
      result = twoLine
      break
    case 'ConST670H':
      result = twoLine
      break
    case 'ConST670U':
      result = threeLine
      break
    default:
      result = twoLine
      break
  }
  return {
    code: 200,
    msg: null,
    data: result,
  }
}

/** 标定系统专用 */

/**
 * 获取压力、温度、电
 * @param {string} type "P/T/E"
 * @returns
 */
export const getTaskPointType = (type) => {
  let pressTaskPoint = [
    { label: '放大倍数', value: 0, shortName: 'AD' },
    { label: '量程标定', value: 1, shortName: 'RS' },
    { label: '温度补偿', value: 2, shortName: 'TC' },
    { label: '压力出数', value: 3, shortName: 'RD' },
  ]
  let result = []
  switch (type) {
    case 'P':
      result = pressTaskPoint
      break
    case 'T':
      break
    case 'E':
      break
    default:
      break
  }
  return result
}

/**
 *获取判稳类型
 *
 * @export
 * @returns
 */
export const getStableType = () => {
  return [
    { label: '波动判稳', value: 0 },
    { label: '达到判稳', value: 1 },
    { label: '延时判稳', value: 2 },
  ]
}

/**
 *获取产品类型
 *
 * @export
 * @returns
 */
export const getProductType = () => {
  return [
    { label: '压力', value: 1 },
    { label: '温度', value: 2 },
    { label: '电学', value: 3 },
  ]
}

/**
 *获取任务线类别
 *
 * @export
 * @returns
 */
export const getTaskClass = () => {
  return [
    { label: 'ESS老化流程', value: 0 },
    { label: '步入式9000标定', value: 1 },
  ]
}

/**
 *获取目标附近值类型
 *
 * @export
 * @returns
 */
export const getTargetNearValueClass = () => {
  return [
    { label: '固定值', value: 0 },
    { label: '范围', value: 1 },
  ]
}

/**
 *获取判稳方式
 *
 * @export
 * @returns
 */
export const getCalculateMode = () => {
  return [
    { label: '平均值', value: 0 },
    { label: '中位值', value: 1 },
    { label: '均方差', value: 2 },
    { label: '众数', value: 3 },
  ]
}
