import VTable from './vTable/VTable.vue'
import VOTable from './vTable/VOperaTable.vue'

import VRangeDate from './vDateRange/DataRange.vue'
import IconSvg from './vIconSvg/vIconSvg.vue'

import AddBtn from './operation/AddButton.vue'

export default {
  VTable,
  VOTable,
  IconSvg,
  VRangeDate,
  AddBtn,
}
