<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view></router-view>
    </el-config-provider>
  </div>
</template>

<script>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
export default {
  computed: {},
  components: { ElConfigProvider },
  data() {
    return {
      locale: zhCn,
    };
  },
};
</script>
