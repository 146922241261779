export default {
  state: {
    //菜单
    navTree: [],
    firsthandTree: [],
  },
  getters: {
    navTree: (state) => {
      if (state.navTree.length == 0) {
        return JSON.parse(localStorage.getItem('navTree'))
      } else {
        return state.navTree
      }
    },
    firsthandTree: (state) => {
      if (state.firsthandTree.length == 0) {
        return JSON.parse(localStorage.getItem('firsthandTree'))
      } else {
        return state.firsthandTree
      }
    },
  },

  mutations: {
    setNavTree(
      state,
      navTree //设置导航菜单树
    ) {
      state.navTree = navTree
      sessionStorage.setItem('navTree', JSON.stringify(navTree))
    },
    setFirsthandTree(
      state,
      firsthandTree //设置导航菜单树
    ) {
      state.firsthandTree = firsthandTree
      sessionStorage.setItem('firsthandTree', JSON.stringify(firsthandTree))
    },
    INCREMENT(state) {
      state.count++
    },
    DECREMENT(state) {
      state.count--
    },
    //标签改变事件
    TagChange(state, index) {
      state.tagIndex = index
    },
  },
  actions: {},
}
