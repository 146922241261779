
import { createStore } from 'vuex'

import app from './modules/app'
import user from './modules/user'
import menu from './modules/menu'
import checkdata from './modules/checkdata'

export default createStore({
  modules: {
    app: app,
    user: user,
    menu: menu,
    checkdata: checkdata,
  },
})


//使用示例
// 赋值 this.$store.state.checkdata.noPassGatherNoPassCompleteMachine=1;
// 取值  var flag = this.$store.state.checkdata.noPassGatherNoPassCompleteMachine;
