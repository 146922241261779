<template>
  <el-form style="margin-top: 20px" :label-width="labelWidth">
    <el-radio-group
      v-model="quick"
      size="small"
      @change="onTimeQuickChangeData"
    >
      <el-radio-button label="0">最近一周</el-radio-button>
      <el-radio-button label="1">最近一个月</el-radio-button>
      <el-radio-button label="2">最近三个月</el-radio-button>
      <el-radio-button label="3">最近半年</el-radio-button>
      <el-radio-button label="4">最近一年</el-radio-button>
      <el-radio-button label="5">自定义</el-radio-button>
    </el-radio-group>
    <el-form-item label="时间范围" style="margin-top: 20px">
      <el-date-picker
        :shortcuts="startDatePicker && startDatePicker.shortcuts"
        :disabled-date="startDatePicker && startDatePicker.disabledDate"
        :cell-class-name="startDatePicker && startDatePicker.cellClassName"
        v-model="beginDate"
        type="date"
        placeholder="开始日期"
        style="width: 40%"
        format="yyyy/MM/dd"
        value-format="yyyy/MM/dd"
        :clearable="false"
        @change="pickerStartChange"
      ></el-date-picker>
      至
      <el-date-picker
        :shortcuts="endDatePicker && endDatePicker.shortcuts"
        :disabled-date="endDatePicker && endDatePicker.disabledDate"
        :cell-class-name="endDatePicker && endDatePicker.cellClassName"
        v-model="endDate"
        type="date"
        placeholder="结束日期"
        style="width: 40%"
        :clearable="false"
        format="yyyy/MM/dd"
        value-format="yyyy/MM/dd"
        @change="pickerEndChange"
      ></el-date-picker>
    </el-form-item>
  </el-form>
</template>

<script>
import { $on, $off, $once, $emit } from '/utils/gogocodeTransfer'
export default {
  data: function () {
    return {
      startDatePicker: this.pickerOptionsBegin(),
      endDatePicker: this.pickerOptionsEnd(),
      beginDate: this.begin,
      endDate: this.end,
      quick: this.quickValue,
    }
  },
  props: {
    begin: {
      type: String,
      default: '',
    },
    end: {
      type: String,
      default: '',
    },
    quickValue: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
      default: '80px',
    },
  },
  methods: {
    //点击快捷键事件
    onTimeQuickChangeData(type) {
      this.setRangeDate()
    },
    //设置区间日期
    setRangeDate() {
      let type = this.quick
      let dateSpan = this.$helper.utility.setRangeDate(type)
      this.beginDate = dateSpan[0]
      this.endDate = dateSpan[1]
    },
    //设置快捷键
    setQuick() {
      let beginDate = new Date(this.beginDate.replace('/', '-'))
      let endDate = new Date(this.endDate.replace('/', '-'))
      this.quick = this.getQuickVal(beginDate, endDate)
    },
    //获取快捷键的值
    getQuickVal(beginDate, endDate) {
      let val = '5'
      if (endDate.getTime() - beginDate.getTime() === 3600 * 1000 * 24 * 7) {
        val = '0'
      }
      if (beginDate.getDate() == endDate.getDate()) {
        if (endDate.getMonth() - beginDate.getMonth() === 1) {
          val = '1'
        } else if (endDate.getMonth() - beginDate.getMonth() === 3) {
          val = '2'
        } else if (endDate.getMonth() - beginDate.getMonth() === 6) {
          val = '3'
        } else if (endDate.getMonth() - beginDate.getMonth() === 12) {
          val = '4'
        }
      }
      return val
    },

    //开始日期改变事件
    pickerStartChange(val) {
      this.setQuick()
    },
    //结束日期改变事件
    pickerEndChange(val) {
      this.setQuick()
    },
    pickerOptionsBegin() {
      const self = this
      return {
        disabledDate(time) {
          if (self.endTime) {
            //如果结束时间不为空，则小于结束时间
            return new Date(self.endTime).getTime() < time.getTime()
          } else {
            return time.getTime() > Date.now() //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      }
    },
    pickerOptionsEnd() {
      const self = this
      return {
        disabledDate(time) {
          if (self.beginTime) {
            //如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.beginTime).getTime() > time.getTime()
          } else {
            return time.getTime() > Date.now() //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      }
    },
  },
  watch: {
    beginDate(val, oldval) {
      console.info('binad')
      $emit(this, 'update:begin', val)
    },
    endDate(val, oldval) {
      $emit(this, 'update:end', val)
    },
    quick(val, oldval) {
      $emit(this, 'update:quickValue', val)
    },
  },
  mounted() {
    if (this.quickValue == '') {
      if (this.begin != '' && this.end != '') {
        this.setQuick()
      }
    } else {
      this.setRangeDate()
    }
  },
  emits: ['update:begin', 'update:end', 'update:quickValue'],
}
</script>
