/**
 *是否授权显示
 *
 * @export
 * @param {*} key   btn的key
 * @returns  true 授权成功  false  授权失败
 */
export function isAuthBtn(key) {
  let authDatas = JSON.parse(sessionStorage.getItem('opraAuth'))
  return authDatas.indexOf(key) > -1
}
