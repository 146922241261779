/***************基础数据模块API**************************************************************/

import request from '../fetch'

/**************************网关管理模块接口*************************************** */

/**
 * 查询获取网关列表
 * @param {object} data
 * @returns {Array}
 */
export function getList(data) {
  return request({
    url: 'api/Gateway/GetList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取单个网关的详细信息
 * @param {string} id  网关ID
 * @returns {object} 单个网关的详细内容
 */
export function getGatewayById(id) {
  return request({
    url: 'api/Gateway/GetGatewayById',
    method: 'get',
    params: { id: id },
  })
}

/**
 * 添加网关
 * @param {object} data
 * @returns {bool} 是否成功
 */
export function addGateway(data) {
  return request({
    url: 'api/Gateway/AddGateway',
    method: 'post',
    data: data,
  })
}

/**
 * 更新网关
 * @param {object} data
 * @returns {bool} 是否成功
 */
export function updateGateway(data) {
  return request({
    url: 'api/Gateway/UpdateGateway',
    method: 'post',
    data: data,
  })
}

/**
 * 删除网关
 * @param {string} id
 * @returns {bool} 是否成功
 */
export function deleteGateway(id) {
  return request({
    url: 'api/Gateway/DeleteGateway',
    method: 'post',
    params: { id: id },
  })
}

/**
 * 删除网关
 * @param {string} id
 * @returns {bool} 是否成功
 */
export function checkGatewaySn(data) {
  return request({
    url: 'api/Gateway/CheckGatewaySn',
    method: 'post',
    params: data,
  })
}
