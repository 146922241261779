export default {
  state: {
    perms: [], //用户权限
    userInfo: {} || sessionStorage.getItem('ms_userinfo'), //用户信息
    userName: '' || sessionStorage.getItem('ms_username'),
    ticket: '' || sessionStorage.getItem('ticket'),
    tokenExpire: '' || sessionStorage.getItem('tokenExpire'),
    operationAuth: [], //操作授权数据
  },
  getters: {
    userName: (state) => state.userName,
    userInfo: (state) => state.userInfo,
    ticket: (state) => state.ticket,
    tokenExpire: (state) => state.tokenExpire,
    operationAuth: (state) => {
      if (state.operationAuth.length == 0) {
        return JSON.parse(sessionStorage.getItem('opraAuth'))
      } else {
        return state.operationAuth
      }
    },
  },
  mutations: {
    setPerms(state, perms) {
      // 用户权限标识集合
      state.perms = perms
    },
    setUserInfo(state, perms) {
      // 用户数据
      state.userInfo = perms
      sessionStorage.setItem('ms_userinfo', JSON.stringify(perms))
    },
    setUserName(state, perms) {
      state.userName = perms
      sessionStorage.setItem('ms_username', perms)
    },
    setTicket(state, perms) {
      state.ticket = perms
      sessionStorage.setItem('ticket', perms)
      window.localStorage.ticket = perms
    },
    setTokenExpire(state, perms) {
      state.tokenExpire = perms
      sessionStorage.setItem('tokenExpire', perms)
    },
    setAuth(state, perms) {
      state.operationAuth = perms
      sessionStorage.setItem('opraAuth', JSON.stringify(perms))
    },
  },
  actions: {},
}
