/***************基础数据模块API**************************************************************/

import request from '../fetch'

/**************************任务管理模块接口*************************************** */

/**
 * 新9000工装分析汇总信息
 * @param {object} param
 * @returns
 */
export function getTaskAnalyzeSummary(param) {
  return request({
    url: 'api/TaskRecord/GetTaskAnalyzeSummary',
    method: 'get',
    params: param,
  })
}

/**
 * 新9000工装分析信息
 * @param param
 * @returns
 */
export function getTaskAnalyzeData(param) {
  return request({
    url: 'api/TaskRecord/GetTaskAnalyzeData',
    method: 'get',
    params: param,
  })
}

/**
 * 新9000工装分析列表信息
 * @param param
 * @returns
 */
export function getTaskAnalyzeList(param) {
  return request({
    url: 'api/TaskRecord/getTaskAnalyzeList',
    method: 'get',
    params: param,
  })
}

/**
 * 获取监控工装列表
 * @param param
 * @returns
 */
export function getToolList(param) {
  return request({
    url: 'api/TaskRecord/GetToolList',
    method: 'get',
    params: param,
  })
}
