/***************公共API**************************************************************/

import request from '../fetch'

/*****公共***************************************************** */

/**
 *获取枚举数据
 *
 * @export
 * @returns
 */

export function getEnumList(data) {
  return request({
    url: '/api/Common/GetEnumList',
    method: 'get',
    params: data,
  })
}

/**
 *aigc创建图片
 *
 * @export
 * @returns
 */

export function createImage(data) {
  return request({
    url: '/api/External/CreateImageAdmin',
    method: 'get',
    params: data,
  })
}

/**
 *aigc创建图片
 *
 * @export
 * @returns
 */

export function mattingImage(data) {
  return request({
    url: '/api/External/MattingImage',
    method: 'get',
    params: data,
  })
}
