// 本JS文件用于应用程序全局调用使用

import store from '../../store'
/** 登录后对应用系统全局变量赋值相关方法 */

//设置登录数据
export const setLoginData = function (data) {
  localStorage.clear()
  //存储菜单数据
  store.commit('setFirsthandTree', data.MenuData) //存储菜单数据
  localStorage.setItem('data', JSON.stringify(data))
  var menuList = getMenuTree(
    '00000000-0000-0000-0000-000000000000',
    data.MenuData
  )
  window.localStorage.navTree = JSON.stringify(menuList) //存储菜单数据
  store.commit('setNavTree', menuList) //存储菜单数据
  store.commit('setUserName', data.UserName) //存储用户名信息
  window.localStorage.ms_username = data.UserName
  store.commit('setTicket', data.Ticket)
  resetRefreshtime(60 * 3)
  store.commit('setAuth', getOperationAuth(data.MenuData))
}
//获取操作权限数据
export const getOperationAuth = function (menuData) {
  let result = []
  menuData
    .filter((item) => {
      return item.Type == '3'
    })
    .forEach((f) => {
      result.push(f.Url)
    })
  return result
}
//获取菜单树(递归)
export const getMenuTree = function (pid, data) {
  function tree(id) {
    let arr = []
    var newList = []
    data
      .filter((item) => {
        return item.ParentId === id && (item.Type == '1' || item.Type == '2')
      })
      .forEach((item) => {
        arr.push({
          Name: item.Name,
          Id: item.Id,
          Url: item.Url,
          ParentId: item.ParentId,
          Icon: item.Icon,
          Children: tree(item.Id),
        })
      })
    return arr
  }
  return tree(pid) // 第一级节点的父id，是null或者0，视情况传入
}

/**
 * 每次使用系统后设置刷新时间过期时间，在当前时间增加相应时间窗隔。
 * 当Token过期后如果刷新时间也过期说明已经长时间未使用系统，需要再次登录。
 * @param {int} params 滑动分钟数
 */
export const resetRefreshtime = (params) => {
  var curTime = new Date()
  if (
    window.localStorage.refreshtime &&
    window.localStorage.refreshtime < curTime
  ) {
    //重新登录时
    return
  }
  // 用当前时间+滑动系数作为新的过期时间。
  window.localStorage.refreshtime = new Date(
    curTime.setMinutes(new Date().getMinutes() + params)
  )
}
