import * as $codes from './codes/index'
import $ui from './ui'
import $helper from './helper'
import $api from '../api'
import components from '../components'
import directives from '../directives'

const register = function (_vue) {
  //注册全局组件
  Object.keys(components).forEach((e) => _vue.component(e, components[e]))
  //注册全局指令
  Object.keys(directives).forEach((e) => _vue.directive(e, directives[e]))
  // 项目扩展 $api, $ui, $codes $helper
  var allExtends = { $api, $ui, $codes, $helper }
  Object.keys(allExtends).forEach((e) => {
    const plugin = {}
    plugin[e] = {
      get() {
        return allExtends[e]
      },
    }
    Object.defineProperties(_vue.config.globalProperties, plugin)
    console.log(_vue )
  })
}
const install = (e) => register(e)
export default {
  install,
  register,
}
