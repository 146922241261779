export default {
  state: {
    testMsgStr: 'checkdata ,hello beijing!',

    /**测试中心 */
    //测试设备
    productionOrderDeviceExtFactory: '',
    deviceGatherDeviceExtFactory: '',
    productionOrderDeviceComponent: '',
    deviceGatherDeviceComponent: '',
    productionOrderDeviceCompleteMachine: '',
    deviceGatherDeviceCompleteMachine: '',

    //错误信息
    noPassTagNoPassExtFactory: '',
    noPassGatherNoPassExtFactory: '',
    noPassTagNoPassComponent: '',
    noPassGatherNoPassComponent: '',
    noPassTagNoPassCompleteMachine: '',
    noPassGatherNoPassCompleteMachine: '',

    //测试子项
    subItemNameSubItemExtFactory: '',
    subItemGatherSubItemExtFactory: '',
    subItemNameSubItemComponent: '',
    subItemGatherSubItemComponent: '',
    subItemNameSubItemCompleteMachine: '',
    subItemGatherSubItemCompleteMachine: '',

    //设备类型
    deviceGatherDeviceModeExtFactory: '',
    deviceModeQueryDeviceModeExtFactory: '',
    deviceGatherDeviceModeComponent: '',
    deviceModeQueryDeviceModeComponent: '',
    deviceGatherDeviceModeCompleteMachine: '',
    deviceModeQueryDeviceModeCompleteMachine: '',

    //测试详情
    detailDeviceCodeExtFactory: '',
    detailGatherExtFactory: '',

    /**维修信息 */
    repairCategoryRepair: '',
    repairGatherRepair: '',

    /*acc9000*/
    acc9000Scroll: 0,
    /*accanalyze*/
    accanalyzeScroll: 0,
    /*ESS*/
    accEssScroll: 0,
  },
  getters: {},
  mutations: {
    // setStateValue(state,value){
    //     state[key]=value;
    // }
  },
  actions: {},
}
