import 'babel-polyfill'
import { createApp } from 'vue'
import App from './App'
import axios from 'axios'
import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/theme-chalk/index.css' // 默认主题
import './assets/css/iconfont/iconfont.js' //引入iconfont图标库
import './assets/css/main.css'
import './assets/css/color-dark.css' /*主题*/
import moment from 'moment' //时间格式
import router from './router'
import _extends from './_extends'
import ZkTable from 'vue-table-with-tree-grid'
import { setLoginData } from './common/js/global'
//import echarts from 'echarts';
import VueContextMenu from 'vue-contextmenu'

window.$vueApp = createApp(App)
window.$vueApp.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith('/') ? '' : '/') + pathToAppend
}
window.$vueApp.use(store)
window.$vueApp.use(router)

window.$vueApp.use(ElementUI)
window.$vueApp.config.globalProperties.$axios = axios
window.$vueApp.config.globalProperties.$moment = moment
window.$vueApp.use(ZkTable)
window.$vueApp.use(VueContextMenu)
_extends.register(window.$vueApp) //扩展注册

Array.prototype.sum = function () {
  var sum = 0
  for (var i = 0; i < this.length; i++) {
    sum += this[i]
  }
  return sum
}

//使用钩子函数对路由器进行权限跳转
router.beforeEach((to, from, next) => {
    // 登录界面登录成功之后，会把用户信息保存在会话
    // 存在时间为会话生命周期，页面关闭即失效。
    document.title = " FG";
    let ticket = sessionStorage.getItem('ticket');
    var token = localStorage.getItem('ticket');
    if (to.path === '/login') {
        next();
        return;
    } else {
        if (token) {
            if (!ticket) {
                setLoginData(JSON.parse(localStorage.data));
                if (from.query.redirect && from.query.redirect == to.path) {
                    next();
                }
                else if (from.query.redirect)
                    next(from.query.redirect);

                else {
                    next();
                }
                return;
            }
            else {
                if (from.query.redirect && from.query.redirect == to.path) {
                    next();
                }
                else if (from.query.redirect)
                    next(from.query.redirect);

                else {
                    next();
                }
                return;
            }
        } else {
            next('/login');
            return;
        }
    }
})

window.$vueApp.mount('#app')