/***************工装管理API**************************************************************/

import request from '../fetch'

/*****工装管理***************************************************** */

/**
 * 获取工装列表
 * @param {*} data
 * @returns
 */
export function getTemplateList(data) {
  return request({
    url: 'api/BasicData/GetTemplateList',
    method: 'post',
    data: data,
  })
}

/**
 * 根据工装ID获取MQ消息服务配置
 * @param {string} id 工装ID
 * @returns
 */
export function queryTemplateMqttConfig(id) {
  return request({
    url: 'api/BasicData/QueryTemplateMqttConfig',
    method: 'get',
    params: {
      id: id,
    },
  })
}
/**
 * 新增工装
 * @param {*} data
 * @returns
 */
export function addTemplate(data) {
  return request({
    url: 'api/BasicData/AddTemplate',
    method: 'post',
    data: data,
  })
}
/**
 * 更新工装
 * @param {*} data
 * @returns
 */
export function updateTemplate(data) {
  return request({
    url: 'api/BasicData/UpdateTemplate',
    method: 'post',
    data: data,
  })
}
/**
 * 删除工装
 * @param {*} data
 * @returns
 */
export function deleteTemplate(data) {
  return request({
    url: 'api/BasicData/DeleteTemplate',
    method: 'get',
    params: data,
  })
}
/**
 * 检查工装名称是否重复
 * @param {*} data
 * @returns
 */
export function checkTemplateNameRepeat(data) {
  return request({
    url: 'api/BasicData/CheckTemplateNameRepeat',
    method: 'post',
    data: data,
  })
}

/**
 * 检查工装SN是否重复
 * @param {*} data
 * @returns
 */
export function checkTemplateSn(data) {
  return request({
    url: 'api/BasicData/CheckTemplateSn',
    method: 'post',
    data: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getTemplateDeviceList(data) {
  return request({
    url: 'api/BasicData/GetTemplateDeviceList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getTemplateDeviceListForTask(data) {
  return request({
    url: 'api/BasicData/GetTemplateDeviceListForTask',
    method: 'get',
    params: data,
  })
}

/**
 * 新增工装设备
 * @param {*} data
 * @returns
 */
export function addTemplateDevice(data) {
  return request({
    url: 'api/BasicData/AddTemplateDevice',
    method: 'post',
    data: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function deleteTemplateDevice(data) {
  return request({
    url: 'api/BasicData/DeleteTemplateDevice',
    method: 'get',
    params: data,
  })
}

/**
 * 更新工装设备配置
 * @param {*} data
 * @returns
 */
export function updateTemplateDevice(data) {
  return request({
    url: 'api/BasicData/UpdateTemplateDevice',
    method: 'post',
    data: data,
  })
}

/**
 *
 * @param {string} TemplateId
 * @param {bool} isEnvDevice
 * @param {bool} isUut
 * @returns {Array} 设备列表
 */
export function getDevicesFromTemplate(TemplateId, isEnvDevice, isUut) {
  return request({
    url: 'api/BasicData/GetDevicesFromTemplate',
    method: 'get',
    params: { TemplateId: TemplateId, isEnvDevice: isEnvDevice, isUut: isUut },
  })
}

/**
 * 检查工装SN是否重复
 * @param {*} data
 * @returns
 */
export function checkEvmDeviceWorkStationRepeat(data) {
  return request({
    url: 'api/BasicData/CheckEvmDeviceWorkStationRepeat',
    method: 'get',
    params: data,
  })
}

/**
 * 工装监控获取工装数据
 * @param {*} data
 * @returns
 */
export function getMonitorTemplateList(data) {
  return request({
    url: 'api/BasicData/GetMonitorTemplateList',
    method: 'get',
    params: data,
  })
}
