/***************基础数据模块API**************************************************************/

import request from '../fetch'

/** 产品分类信息 **/
//向下查询产品分类树
export function queryProductCategoryTreeToDown(id) {
  return request({
    url: 'api/Product/QueryProductCategoryTreeToDown',
    method: 'get',
    params: { id: id },
  })
}

//向下查询产品分类树
export function queryProductCategoryTree(id) {
  return request({
    url: 'api/Product/QueryProductCategoryTree',
    method: 'get',
    params: { id: id },
  })
}

//向上查询产品分类列表
export function queryProductCategoryTreeToUp(id) {
  return request({
    url: 'api/Product/QueryProductCategoryTreeToUp',
    method: 'get',
    params: { id: id },
  })
}

//产品分类 树列表
export function getCategoryList(data) {
  return request({
    url: 'api/Product/GetCategoryList',
    method: 'post',
    data: data,
  })
}

//新增 产品分类
export function addCategory(data) {
  return request({
    url: 'api/Product/AddCategory',
    method: 'post',
    data: data,
  })
}

//编辑 产品分类
export function updateCategory(data) {
  return request({
    url: 'api/Product/UpdateCategory',
    method: 'post',
    data: data,
  })
}

//删除 产品分类
export function deleteCategory(data) {
  return request({
    url: 'api/Product/DeleteCategory',
    method: 'get',
    params: data,
  })
}

//产品分类名称、代码查重
export function judgeCategory(data) {
  return request({
    url: 'api/Product/JudgeCategory',
    method: 'post',
    data: data,
  })
}

/** 产品分类信息 **/

/** 产品信息 **/

/**
 * 获取产品信息根据Id
 * @param {*} data
 * @returns
 */
export function getProductInfoById(id) {
  return request({
    url: 'api/Product/GetProductInfoById',
    method: 'get',
    params: { id: id },
  })
}

/**
 * 获取产品信息数据列表
 * @param {*} data
 * @returns
 */
export function getList(data) {
  return request({
    url: 'api/Product/GetList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取全部设备列表
 * @param {*} data
 * @returns
 */
export function getDeviceList(data) {
  return request({
    url: 'api/Product/GetDeviceList',
    method: 'get',
    params: data,
  })
}

/**
 * 添加产品
 * @param {*} data
 * @returns
 */
export function addProduct(data) {
  return request({
    url: 'api/Product/AddProduct',
    method: 'post',
    data: data,
  })
}

/**
 * 更新产品基本信息
 * @param {*} data
 * @returns
 */
export function updateProductBaseInfo(data) {
  return request({
    url: 'api/Product/UpdateProductBaseInfo',
    method: 'post',
    data: data,
  })
}

/**
 * 更新产品规则实例
 * @param {*} data
 * @returns
 */
export function updateProductRuleInstance(data) {
  return request({
    url: 'api/Product/UpdateProductRuleInstance',
    method: 'post',
    data: data,
  })
}

/**
 * 删除产品信息
 * @param {*} data
 * @returns
 */
export function deleteProductInfo(productId) {
  return request({
    url: 'api/Product/DeleteProductInfo',
    method: 'post',
    params: { productId: productId },
  })
}

/**
 * 校验产品中某个字段是否已经存在
 * @param {*} data
 * @returns
 */
export function checkColumnIsExist(data) {
  return request({
    url: 'api/Product/CheckColumnIsExist',
    method: 'get',
    params: data,
  })
}

/**
 * 判断产品信息是否已经被批次数据应用
 * @param {*} data
 * @returns
 */
export function checkProductIsUsedByBatch(id) {
  return request({
    url: 'api/Product/CheckProductIsUsedByBatch',
    method: 'post',
    params: { id: id },
  })
}
/** 产品信息 **/

/** 产品BOM **/

/**
 * 获取产品BOM主数据列表
 * @param {*} data
 * @returns
 */
export function getAssmblyStanderList(data) {
  return request({
    url: 'api/Assembly/GetAssemblyStandardList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取产品BOM树数据
 * @param {*} data
 * @returns
 */
export function getAssmblyStanderDetailList(data) {
  return request({
    url: 'api/Assembly/GetAssemblyStandardDetailList',
    method: 'get',
    params: data,
  })
}

/**
 * 新增产品BOM
 * @param {*} data
 * @returns
 */
export function addAssemblyStandard(data) {
  return request({
    url: 'api/Assembly/AddAssemblyStandard',
    method: 'post',
    data: data,
  })
}

/**
 * 更新产品BOM
 * @param {*} data
 * @returns
 */
export function updateAssemblyStandard(data) {
  return request({
    url: 'api/Assembly/UpdateAssemblyStandard',
    method: 'post',
    data: data,
  })
}

/**
 * 删除产品BOM
 * @param {*} data
 * @returns
 */
export function deleteAssemblyStandard(data) {
  return request({
    url: 'api/Assembly/DeleteAssemblyStandard',
    method: 'get',
    params: data,
  })
}

/** 产品BOM **/
