/***************工装管理API**************************************************************/

import request from '../fetch'

/*****工装管理***************************************************** */

/**
 * 获取工装列表
 * @param {*} data
 * @returns
 */
export function getPrintList(data) {
  return request({
    url: 'api/UserData/GetPrintList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取工装列表
 * @param {*} data
 * @returns
 */
export function deletePrint(data) {
  return request({
    url: 'api/UserData/DeletePrint',
    method: 'get',
    params: data,
  })
}

/**
 * 获取工装列表
 * @param {*} data
 * @returns
 */
export function updatePrint(data) {
  return request({
    url: 'api/UserData/UpdatePrint',
    method: 'post',
    data: data,
  })
}
