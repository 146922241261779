export default {
  state: {
    menuAndRouteLoaded: false, //菜单和路由是否已经加载
  },
  getters: {
    collapse(state) {
      return state.collapse
    },
  },
  mutations: {
    menuRouteLoaded(state, menuAndRouteLoaded) {
      //菜单和路由加载
      state.menuAndRouteLoaded = menuAndRouteLoaded
    },
  },
  actions: {},
}
