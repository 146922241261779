import { ElMessage as Message, ElMessageBox as MessageBox } from 'element-plus'

export function message(options) {
  return Message(options)
}
export const info = (msg, opt) => {
  _currentMessage({
    message: msg,
    type: 'info',
    showClose: true,
    duration: 1500,
    ...opt,
  })
}
export const warn = (msg, duration = 2000, opt) => {
  _currentMessage({
    message: msg,
    type: 'warning',
    showClose: true,
    duration: duration,
    ...opt,
  })
}
export const success = (msg, opt) => {
  _currentMessage({
    message: msg,
    type: 'success',
    showClose: true,
    duration: 1500,
    ...opt,
  })
}
export const confirm = (content, opt) => {
  return _currentConfirm(
    content,
    '提示',
    Object.assign(
      {
        type: 'warning',
      },
      opt
    )
  )
}

function _currentMessage(opt) {
  return Message(opt)
}

function _currentConfirm(content, title, opt) {
  return MessageBox.confirm(content, title, opt)
  // return window.__currentApp.$confirm(content, title, opt)
}
