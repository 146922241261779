/***************工装管理API**************************************************************/

import request from '../fetch'

/*****工装管理***************************************************** */

/**
 * 获取工装列表
 * @param {*} data
 * @returns
 */
export function getTemplateList(data) {
  return request({
    url: 'api/BasicData/GetTemplateList',
    method: 'post',
    data: data,
  })
}

/**
 * 新增工装
 * @param {*} data
 * @returns
 */
export function addTemplate(data) {
  return request({
    url: 'api/BasicData/AddTemplate',
    method: 'post',
    data: data,
  })
}
/**
 * 更新工装
 * @param {*} data
 * @returns
 */
export function updateTemplate(data) {
  return request({
    url: 'api/BasicData/UpdateTemplate',
    method: 'post',
    data: data,
  })
}
/**
 * 删除工装
 * @param {*} data
 * @returns
 */
export function deleteTemplate(data) {
  return request({
    url: 'api/BasicData/DeleteTemplate',
    method: 'get',
    params: data,
  })
}

/**
 * 获取工装列表
 * @param {*} data
 * @returns
 */
export function getTemplateColorList(data) {
  return request({
    url: 'api/BasicData/GetTemplateColorList',
    method: 'get',
    params: data,
  })
}

/**
 * 根据ID获取工装
 * @param {*} data
 * @returns
 */
export function getTemplateColorById(data) {
  return request({
    url: 'api/BasicData/GetTemplateColorById',
    method: 'get',
    params: data,
  })
}

/**
 * 新增工装
 * @param {*} data
 * @returns
 */
export function addTemplateColor(data) {
  return request({
    url: 'api/BasicData/AddTemplateColor',
    method: 'post',
    data: data,
  })
}
/**
 * 更新工装
 * @param {*} data
 * @returns
 */
export function updateTemplateColor(data) {
  return request({
    url: 'api/BasicData/UpdateTemplateColor',
    method: 'post',
    data: data,
  })
}
/**
 * 删除工装
 * @param {*} data
 * @returns
 */
export function deleteTemplateColor(data) {
  return request({
    url: 'api/BasicData/DeleteTemplateColor',
    method: 'get',
    params: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getTemplateDescList(data) {
  return request({
    url: 'api/BasicData/GetTemplateDescList',
    method: 'get',
    params: data,
  })
}

/**
 * 新增工装设备
 * @param {*} data
 * @returns
 */
export function addTemplateDesc(data) {
  return request({
    url: 'api/BasicData/AddTemplateDesc',
    method: 'post',
    data: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function deleteTemplateDesc(data) {
  return request({
    url: 'api/BasicData/DeleteTemplateDesc',
    method: 'get',
    params: data,
  })
}

/**
 * 更新工装设备配置
 * @param {*} data
 * @returns
 */
export function updateTemplateDesc(data) {
  return request({
    url: 'api/BasicData/UpdateTemplateDesc',
    method: 'post',
    data: data,
  })
}

/**
 * 检查工装SN是否重复
 * @param {*} data
 * @returns
 */
export function checkEvmDescWorkStationRepeat(data) {
  return request({
    url: 'api/BasicData/CheckEvmDescWorkStationRepeat',
    method: 'get',
    params: data,
  })
}

/**
 * 工装监控获取工装数据
 * @param {*} data
 * @returns
 */
export function getMonitorTemplateList(data) {
  return request({
    url: 'api/BasicData/GetMonitorTemplateList',
    method: 'get',
    params: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getCarouselList(data) {
  return request({
    url: 'api/BasicData/GetCarouselList',
    method: 'post',
    data: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function getCarouselById(data) {
  return request({
    url: 'api/BasicData/GetCarouselById',
    method: 'get',
    params: data,
  })
}

/**
 * 新增工装设备
 * @param {*} data
 * @returns
 */
export function addCarousel(data) {
  return request({
    url: 'api/BasicData/AddCarousel',
    method: 'post',
    data: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function deleteCarousel(data) {
  return request({
    url: 'api/BasicData/DeleteCarousel',
    method: 'get',
    params: data,
  })
}

/**
 * 更新工装设备配置
 * @param {*} data
 * @returns
 */
export function updateCarousel(data) {
  return request({
    url: 'api/BasicData/UpdateCarousel',
    method: 'post',
    data: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getCategoryList(data) {
  return request({
    url: 'api/BasicData/GetCategoryList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getCategories(data) {
  return request({
    url: 'api/BasicData/GetCategories',
    method: 'get',
    params: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function getCategoryById(data) {
  return request({
    url: 'api/BasicData/GetCategoryById',
    method: 'get',
    params: data,
  })
}

/**
 * 新增工装设备
 * @param {*} data
 * @returns
 */
export function addCategory(data) {
  return request({
    url: 'api/BasicData/AddCategory',
    method: 'post',
    data: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function deleteCategory(data) {
  return request({
    url: 'api/BasicData/DeleteCategory',
    method: 'get',
    params: data,
  })
}

/**
 * 更新工装设备配置
 * @param {*} data
 * @returns
 */
export function updateCategory(data) {
  return request({
    url: 'api/BasicData/UpdateCategory',
    method: 'post',
    data: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getSupplierList(data) {
  return request({
    url: 'api/BasicData/GetSupplierList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取工装设备列表
 * @param {*} data
 * @returns
 */
export function getSupplieies(data) {
  return request({
    url: 'api/BasicData/GetSupplieies',
    method: 'get',
    params: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function getSupplierById(data) {
  return request({
    url: 'api/BasicData/GetSupplierById',
    method: 'get',
    params: data,
  })
}

/**
 * 新增工装设备
 * @param {*} data
 * @returns
 */
export function addSupplier(data) {
  return request({
    url: 'api/BasicData/AddSupplier',
    method: 'post',
    data: data,
  })
}

/**
 * 删除工装设备
 * @param {*} data
 * @returns
 */
export function deleteSupplier(data) {
  return request({
    url: 'api/BasicData/DeleteSupplier',
    method: 'get',
    params: data,
  })
}

/**
 * 更新工装设备配置
 * @param {*} data
 * @returns
 */
export function updateSupplier(data) {
  return request({
    url: 'api/BasicData/UpdateSupplier',
    method: 'post',
    data: data,
  })
}
