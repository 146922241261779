/***************基础数据模块API**************************************************************/

import request from '../fetch'

/**************************任务点管理模块接口*************************************** */

/**
 * 查询获取任务点列表
 * @param {object} data
 * @returns {Array}
 */
export function getList(data) {
  return request({
    url: 'api/TaskPoint/GetList',
    method: 'post',
    params: data,
  })
}

/**
 * 获取单个任务点的详细信息
 * @param {string} id  任务点ID
 * @returns {object} 单个任务点的详细内容
 */
export function getTaskPointById(id) {
  return request({
    url: 'api/TaskPoint/GetTaskPointById',
    method: 'get',
    params: { id: id },
  })
}

/**
 * 添加任务点
 * @param {object} data
 * @returns {bool} 是否成功
 */
export function addTaskPoint(data) {
  return request({
    url: 'api/TaskPoint/AddTaskPoint',
    method: 'post',
    data: data,
  })
}

/**
 * 更新任务点
 * @param {object} data
 * @returns {bool} 是否成功
 */
export function updateTaskPoint(data) {
  return request({
    url: 'api/TaskPoint/UpdateTaskPoint',
    method: 'post',
    data: data,
  })
}

/**
 * 删除任务点
 * @param {string} id
 * @returns {bool} 是否成功
 */
export function deleteTaskPoint(id) {
  return request({
    url: 'api/TaskPoint/DeleteTaskPoint',
    method: 'post',
    params: { id: id },
  })
}

/**
 * 移动任务点
 * @param {object} arg 移动任务点
 * @returns bool
 */
export function moveTaskPoint(arg) {
  return request({
    url: 'api/TaskPoint/MoveTaskPoint',
    method: 'post',
    data: arg,
  })
}
