/***************基础数据模块API**************************************************************/

import request from '../fetch'

/**************************任务管理模块接口*************************************** */

/**
 * 查询获取任务列表
 * @param {object} data
 * @returns {Array}
 */
export function getList(data) {
  return request({
    url: 'api/TaskLine/GetList',
    method: 'post',
    params: data,
  })
}

/**
 * 获取单个任务的详细信息
 * @param {string} id  任务ID
 * @returns {object} 单个任务的详细内容
 */
export function getTaskLineById(id) {
  return request({
    url: 'api/TaskLine/GetTaskLineById',
    method: 'get',
    params: { id: id },
  })
}

/**
 * 添加任务
 * @param {object} data
 * @returns {bool} 是否成功
 */
export function addTaskLine(data) {
  return request({
    url: 'api/TaskLine/AddTaskLine',
    method: 'post',
    data: data,
  })
}

/**
 * 更新任务
 * @param {object} data
 * @returns {bool} 是否成功
 */
export function updateTaskLine(data) {
  return request({
    url: 'api/TaskLine/UpdateTaskLine',
    method: 'post',
    data: data,
  })
}

/**
 * 删除任务
 * @param {string} id
 * @returns {bool} 是否成功
 */
export function deleteTaskLine(id) {
  return request({
    url: 'api/TaskLine/DeleteTaskLine',
    method: 'post',
    params: { id: id },
  })
}

/**
 * 复制任务
 * @param {string} id
 * @returns {bool} 是否成功
 */
export function copyTaskLine(id) {
  return request({
    url: 'api/TaskLine/CopyTaskLine',
    method: 'post',
    params: { id: id },
  })
}

/**
 * 校验任务名称是否重复
 * @param {string} toolId 工装id
 * @param {string} taskName 任务名称
 * @param {string} id 任务ID
 * @returns
 */
export function CheckIsExist(toolId, taskName, id) {
  return request({
    url: 'api/TaskLine/CheckIsExist',
    method: 'get',
    params: { toolId: toolId, taskName: taskName, id: id },
  })
}

/**
 * 从数据中心分页查询传感器量程对照表列表
 * @param {object} arg 查询参数： ID ModelFlag  PageSize PageIndex
 * @returns 数据列表
 */
export function getSensorsFromDAS(arg) {
  return request({
    url: 'api/TaskLine/GetSensorsFromDAS',
    method: 'post',
    data: arg,
  })
}

/**
 * 校验任务的合法性
 * @param {object} data 任务线
 * @returns bool 是否成功
 */
export function checkTaskLine(data) {
  return request({
    url: 'api/TaskLine/CheckTaskLine',
    method: 'post',
    params: data,
  })
}

/**
 * 检查任务线是否存在
 * @param {string} id 任务线Id
 * @returns {bool} 是否存在
 */
export function checkTaskLineIsExist(id) {
  return request({
    url: 'api/TaskLine/CheckTaskLineIsExist',
    method: 'post',
    params: { id: id },
  })
}
