/**
 *设置区间时间
 *
 * @param {*} type 0 最近一周  1最近一个月 2最近三个月 3最近半年  4最近一年
 */
export const setRangeDate = (type) => {
  var start = new Date()
  var end = new Date()
  switch (type) {
    case '0':
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      break
    case '1':
      start.setMonth(start.getMonth() - 1)
      break
    case '2':
      start.setMonth(start.getMonth() - 3)
      break
    case '3':
      start.setMonth(start.getMonth() - 6)
      break
    case '4':
      start.setMonth(start.getMonth() - 12)
      break
    case '5':
      end.setMonth(end.getMonth() + 12)
      break
    default:
      start.setMonth(start.getMonth() - 2)
      break
  }
  start =
    start.getFullYear() + '/' + (start.getMonth() + 1) + '/' + start.getDate()
  end = end.getFullYear() + '/' + (end.getMonth() + 1) + '/' + end.getDate()
  return [start, end]
}

/**
 *设置区间时间
 *
 * @param
 */
export const getGMTToStr = (time) => {
  let date = new Date(time)
  let Str =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1) +
    '-' +
    date.getDate() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes() +
    ':' +
    date.getSeconds()
  return Str
}

/**
 * 获取 操作按钮权限
 *
 * @param
 */
export const getShowButton = (name, lst) => {
  //var lst=this.$store.getters.firsthandTree;
  console.log(lst)
  if (lst == null) {
    return false
  }
  var flag = false
  for (let btn of lst) {
    if (btn.Url == name) {
      flag = true
    }
  }
  return flag
}

export const getGuid16 = () => {
  var len = 16
  var radix = 16
  var chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  var uuid = [],
    radix = radix || chars.length
  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    // rfc4122, version 4 form
    var r
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}

export const getGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
