import * as vue from 'vue'
import router from '@/router'
import { ElLoading as Loading, ElMessage as Message } from 'element-plus'
import axios from 'axios'
import _config from '@/_config'
import store from '../store'
import { resetRefreshtime } from '../common/js/global'
import { info } from '../_extends/ui/pages'
// import {Vue} from 'vue';
// import qs from 'qs'
const instance = axios.create({
  baseURL: _config.apiBaseUrl, // api的base_url
  timeout: 50000, // 请求超时时间
  // transformRequest: data => qs.stringify(data)
})
var loadingRequestCount = 0
var loadingInstance = null
const showLoading = (className, str) => {
  if (loadingRequestCount === 0) {
    // element的服务方式 target 我这边取的是表格 项目是后台系统 每个页面都有表格 类似整个表格loading
    //和在表格配置v-loading一样的效果，这么做是全局实现了，不用每个页面单独去v-loading
    if (loadingInstance != null) {
      loadingInstance = null
      setTimeout(function () {
        loadingInstance = Loading.service({
          target: className ? className : '.dataArea',
          text: str == null ? '请稍等...' : str,
          background: 'rgba(255, 255, 255, 0.6)',
          lock: true,
        })
      }, 100)
    } else {
      loadingInstance = Loading.service({
        target: className ? className : '.dataArea',
        text: str == null ? '请稍等...' : str,
        background: 'rgba(255, 255, 255, 0.6)',
        lock: true,
      })
    }
  }
  loadingRequestCount++
}

const hideLoading = () => {
  if (loadingRequestCount <= 0) {
    return
  } else {
    loadingRequestCount--
    if (loadingRequestCount === 0) {
      vue.nextTick(() => {
        if (loadingInstance != null) {
          loadingInstance.close()
        }
      })
    }
  }
}

// request拦截器
instance.interceptors.request.use(
  (e) => {
    e.method = e.method
    e.url = _config.suffix ? e.url + _config.suffix : e.url
    e.params = e.params || {}
    e.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${window.localStorage.ticket}`,
    }
    e.withCredentials = true
    e.crossDomain = true
    // 配置 ShowLoading 和  showLoadingContent
    if (!!e.data && e.data.ShowLoading == true) {
      showLoading(e.data.ClassName)
    } else if (!!e.params && e.params.ShowLoading == true) {
      showLoading(e.params.ClassName)
    }
    return e
  },
  (error) => ({
    status: 0,
    msg: error.message,
  })
)
// respone拦截器
instance.interceptors.response.use(
  (response) => {
    if (loadingRequestCount > 0) {
      setTimeout(function () {
        hideLoading()
      }, 400)
    }
    const resp = response.data
    if (response.status === 200) {
      //请求成功后更新刷新时间
      resetRefreshtime(180)
      return resp
    }
    return response
  },
  async (error) => {
    if (loadingRequestCount > 0) {
      setTimeout(function () {
        hideLoading()
      }, 200)
    }

    let errInfo = {
      IsSuccess: false,
      Msg: '错误',
    }
    var originalRequest = error.config
    if (
      error.code == 'ECONNABORTED' &&
      error.message.indexOf('timeout') != -1 &&
      !originalRequest._retry
    ) {
      errInfo.Msg = '请求超时！'
      originalRequest._retry = true
    } else if (error.response) {
      if (error.response.status == 401) {
        var curTime = new Date()
        var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
        // 在用户操作的活跃期内
        if (window.localStorage.refreshtime && curTime <= refreshtime) {
          return await refreshToken(window.localStorage.ticket)
            .then(async (res) => {
              if (res.IsSuccess) {
                window.localStorage.ticket = res.Data.Token
                store.commit('setTicket', res.Data.Token)

                var expiredate = new Date(
                  curTime.setSeconds(curTime.getSeconds() + res.Data.Expires_in)
                )
                store.commit('setTokenExpire', expiredate)

                //location.reload();
                error.config.__isRetryRequest = true
                error.config.headers.Authorization = 'Bearer ' + res.Data.Token

                return await axios(error.config).then((re) => {
                  return re.data
                })
              } else {
                // 刷新token失败 清除token信息并跳转到登录页面
                ToLogin()
                errInfo.Msg = '长时间未操作请重新登录'
              }
            })
            .catch((res) => {})
        } else {
          // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
          ToLogin()
          errInfo.Msg = '长时间未操作请重新登录'
        }
      }
      // 403 无权限
      else if (error.response.status == 403) {
        window.location.href = '/#/403'
      }
      // 429 ip限流
      else if (error.response.status == 429) {
        errInfo.Msg = '刷新次数过多，请稍事休息重试！'
      } else if (error.response.status == 404) {
        // 404 不存在
        window.location.href = '/#/404'
      } else if (error.response.status == 500) {
        // 500 服务器异常
        window.location.href = '/#/500'
      } else {
        //其他错误参数
        window.location.href = '/#/500'
      }
    } else {
      errInfo.Msg = '失败！服务器断开'
    }
    return errInfo // 返回接口返回的错误信息
  }
)

const ToLogin = (params) => {
  store.commit('setTicket', '')
  store.commit('setTokenExpire', '')

  localStorage.clear()
  sessionStorage.clear()
  store.commit('setNavTree', [])
  store.commit('setUserName', '') //存储用户名信息

  if (global.IS_IDS4) {
    applicationUserManager.login()
  } else {
    router.replace({
      path: '/login',
      query: {
        redirect: router.currentRoute.fullPath,
      },
    })
    //window.location.reload()
  }
}

//刷新token
export const refreshToken = async (param) => {
  let data = new FormData()
  data.append('token', param)
  return await axios
    .post(_config.apiBaseUrl + 'api/db/sys/user/refreshToken', data)
    .then((res) => res.data)
}
export default instance
