<template>
  <el-button
    type="primary"
    @click="add()"
    :icon="ElIconPosition"
    v-show="showButton"
    >添加</el-button
  >
</template>

<script>
import { Position as ElIconPosition } from '@element-plus/icons'
import { $on, $off, $once, $emit } from '/utils/gogocodeTransfer'
export default {
  data() {
    return {
      showButton: false,
      btnName: this.name,
      ElIconPosition,
    }
  },
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.isShowButton()
  },
  methods: {
    isShowButton() {
      //console.log(this.btnName);
      if (this.btnName == '') {
        this.showButton = false
        return
      }
      var lst = this.$store.getters.operationAuth
      if (lst == null) {
        this.showButton = false
        return
      }
      var flag = false
      for (let btn of lst) {
        if (btn == this.btnName) {
          this.showButton = true
        }
      }
    },
    add() {
      // 1 代表 新增
      $emit(this, 'add', '1')
    },
  },
  emits: ['add'],
}
</script>
