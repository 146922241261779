/***************基础数据模块API**************************************************************/

import request from '../fetch'

/** 产品BOM **/

/**
 * 获取产品BOM主数据列表
 * @param {*} data
 * @returns
 */
export function getAssemblyStandardList(data) {
  return request({
    url: 'api/Assembly/GetAssemblyStandardList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取产品BOM树数据
 * @param {*} data
 * @returns
 */
export function getAssemblyStandardDetailList(data) {
  return request({
    url: 'api/Assembly/GetAssemblyStandardDetailList',
    method: 'get',
    params: data,
  })
}

/**
 * 检验组装标准名称是否存在
 * @param {*} data
 * @returns
 */
export function checkStandardNameIsExist(data) {
  return request({
    url: 'api/Assembly/CheckStandardNameIsExist',
    method: 'get',
    params: data,
  })
}

/**
 * 新增产品BOM
 * @param {*} data
 * @returns
 */
export function addAssemblyStandard(data) {
  return request({
    url: 'api/Assembly/AddAssemblyStandard',
    method: 'post',
    data: data,
  })
}

/**
 * 更新产品BOM
 * @param {*} data
 * @returns
 */
export function updateAssemblyStandard(data) {
  return request({
    url: 'api/Assembly/UpdateAssemblyStandard',
    method: 'post',
    data: data,
  })
}

/**
 * 删除产品BOM
 * @param {*} data
 * @returns
 */
export function deleteAssemblyStandard(data) {
  return request({
    url: 'api/Assembly/DeleteAssemblyStandard',
    method: 'get',
    params: data,
  })
}

/**
 * 以产品ID获取该产品下所有产品BOM
 * @param {*} data
 * @returns
 */
export function getAssemblyStandardByProductId(data) {
  return request({
    url: 'api/Assembly/GetAssemblyStandardByProductId',
    method: 'get',
    params: data,
  })
}

/** 产品BOM **/

/** 组装产品 */

/**
 * 根据SN查找产品类型
 * @param {*} data
 * @returns
 */
export function queryProductType(data) {
  return request({
    url: 'api/Assembly/queryProductType',
    method: 'get',
    params: { sn: data },
  })
}

/**
 * 查询具体设备下的组装标准列表（如果是设备的情况下调用）
 * @param {*} data
 * @returns
 */
export function queryAssemblyStanderByDeviceSn(data) {
  return request({
    url: 'api/Assembly/QueryAssemblyStanderByDeviceSn',
    method: 'get',
    params: { sn: data },
  })
}

/**
 * 根据输入SN查询组装标准详情，且反查标准详情节点带设备SN号(设备)
 * @param {*} data
 * @returns
 */
export function queryDeviceAssemblyStandardDetailWithSN(data) {
  return request({
    url: 'api/Assembly/QueryDeviceAssemblyStandardDetailWithSN',
    method: 'post',
    params: data,
  })
}

/**
 * 根据输入SN查询组装标准详情，且反查标准详情节点带设备SN号(组件)
 * @param { *} data
 * @returns
 */
export function queryComponentAssemblyStandardDetailWithSN(data) {
  return request({
    url: 'api/Assembly/QueryComponentAssemblyStandardDetailWithSN',
    method: 'post',
    params: { sn: data },
  })
}

/**
 * 根据SN和组装标准ID查询对应产品所在组装详情列表
 * 用于一个组装标准下有多个相同产品组成时。给前台判断放在哪个位置输入框较为合适
 * @param {*} data
 * @returns
 */
export function queryAssemblyDetails(data) {
  return request({
    url: 'api/Assembly/QueryAssemblyDetails',
    method: 'get',
    params: data,
  })
}

/**
 * 扫码组装设备
 * @param {*} data
 * @returns
 */
export function assemblyDevice(data) {
  return request({
    url: 'api/Assembly/AssemblyDevice',
    method: 'post',
    data: data,
  })
}

/**
 * 校验是否已经存在绑定关系
 * @param {*} data
 * @returns
 */
export function CheckIsExistAssemblyRecord(data) {
  return request({
    url: 'api/Assembly/CheckIsExistAssemblyRecord',
    method: 'post',
    params: { sn: data },
  })
}

/**
 * 解绑设备
 * @param {*} data
 * @returns
 */
export function deleteAssemblyRecord(data) {
  return request({
    url: 'api/Assembly/DeleteAssemblyRecord',
    method: 'post',
    params: data,
  })
}

/**
 * 查询产品的组装记录
 * @param {*} data
 * @returns
 */
export function queryAssemblyRecords(data) {
  return request({
    url: 'api/Assembly/QueryAssemblyRecords',
    method: 'get',
    params: data,
  })
}

/**
 * 判断设备是否在组装中
 * @param {*} data
 * @returns
 */
export function checkDeviceIsAssembling(data) {
  return request({
    url: 'api/Assembly/CheckDeviceIsAssembling',
    method: 'get',
    params: { sn: data },
  })
}
