/***************订单管理API**************************************************************/

import request from '../fetch'

/*****订单管理***************************************************** */

/**
 * 获取订单列表
 * @param {*} data
 * @returns
 */
export function getOrderList(data) {
  return request({
    url: 'api/Order/GetOrderList',
    method: 'post',
    data: data,
  })
}

/**
 * 订单发货
 * @param {*} data
 * @returns
 */
export function orderDeliver(data) {
  return request({
    url: 'api/Order/OrderDeliver',
    method: 'post',
    data: data,
  })
}
