
import { createRouter, createWebHashHistory } from 'vue-router'

/**
 * 重写路由的push方法
 */
let router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/default',
    },
    {
      path: '/',
      component: () => import('../view/common/Home.vue'),
      meta: { title: '首页1' },
      children: [
        {
          path: '/default',
          component: () =>
            import('../view/common/Default.vue'),
          meta: { title: '首页' },
        },
        {
          name: 'user',
          path: '/user',
          component: () =>
            import('../view/page/system/user/User-Index.vue'),
          meta: { title: '用户管理', keepAlive: true },
        },
        {
          name: 'role',
          path: '/role',
          component: () =>
            import('../view/page/system/role/Role-Index.vue'),
          meta: { title: '角色管理', keepAlive: true },
        },
        {
          name: 'menu',
          path: '/menu',
          component: () =>
            import('../view/page/system/menu/Menu-Index.vue'),
          meta: { title: '菜单管理', keepAlive: true },
        },
        //****设备管理**********************************************************************************************************************
        {
          name: 'templateManage',
          path: '/templateManage',
          component: () =>
            import(
              '../view/page/BasicData/TemplateManage/Template-List.vue'
            ),
          meta: { title: '模板管理', keepAlive: true },
        },
        //****判稳参数**********************************************************************************************************************
        {
          name: 'carousel',
          path: '/carousel',
          component: () =>
            import(
              '../view/page/BasicData/CarouselManage/Carousel-List.vue'
            ),
          meta: { title: '判稳参数', keepAlive: true },
        },
        //****用户数据管理**********************************************************************************************************************
        {
          name: 'print',
          path: '/print',
          component: () =>
            import('../view/page/UserData/PrintManage/Print-List.vue'),
          meta: { title: '印花图管理', keepAlive: true },
        },
        {
          name: 'order',
          path: '/order',
          component: () =>
            import('../view/page/Order/Order/Order-List.vue'),
          meta: { title: '订单管理', keepAlive: true },
        },
        //****基础数据**********************************************************************************************************************
        {
          name: 'supplier',
          path: '/supplier',
          component: () =>
            import(
              '../view/page/BasicData/Supplier/Supplier-List.vue'
            ),
          meta: { title: '工装分析汇总功能', keepAlive: true },
        },
        {
          name: 'category',
          path: '/category',
          component: () =>
            import(
              '../view/page/BasicData/CategoryManage/Category-List.vue'
            ),
          meta: { title: '网关管理', keepAlive: true },
        },
        {
          name: 'toolAnalyze',
          path: '/toolAnalyze',
          component: () =>
            import('../view/page/ToolAnalyze/ToolAnalyze.vue'),
          meta: { title: '工装分析汇总图表', keepAlive: true },
        },
        {
          name: 'toolAnalyzeList',
          path: '/ToolAnalyzeList',
          component: () =>
            import('../view/page/ToolAnalyze/ToolAnalyze-List.vue'),
          meta: { title: '工装分析表格', keepAlive: true },
        },
        {
          name: 'toolAnalyzeSingle',
          path: '/ToolAnalyzeSingle',
          component: () =>
            import(
              '../view/page/ToolAnalyze/ToolAnalyzeSingle.vue'
            ),
          meta: { title: '工装分析单工装图表', keepAlive: true },
        },
      ],
    },
    {
      path: '/login',
      component: () => import('../view/page/UserLogin.vue'),
    },
    {
      path: '/test',
      component: () => import('../view/test.vue'),
    },
    {
      path: '/404',
      component: () => import('../view/page/404.vue'),
      meta: { title: '404', keepAlive: true },
    },
    {
      path: '/403',
      component: () => import('../view/page/403.vue'),
      meta: { title: '403', keepAlive: true },
    },
    {
      path: '/500',
      component: () => import('../view/page/500.vue'),
      meta: { title: '500', keepAlive: true },
    },
  ],
})

export default router
