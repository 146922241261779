<template>
  <div>
    <div class="table-div">
      <!-- 表格栏 -->
      <el-table
        v-loading="loading"
        :border="border"
        :data="data.data"
        :size="size"
        :stripe="stripe"
        :align="align"
        :height="height"
        :row-class-name="tableRowClassName"
        @row-dblclick="onRowdbClick"
        @row-contextmenu="rightClick"
        :cell-style="cellStyle"
        @selection-change="handleSelectionChange"
        @current-change="handleCurrentChange"
        :show-overflow-tooltip="showColTooltip"
        @row-click="selectRow"
        ref="mainTable"
        :cell-class-name="cellClassName"
        :header-cell-class-name="headerCellClassName"
        :highlight-current-row="highlightCurrentRow"
      >
        <slot name="expand"> </slot>
        <el-table-column type="selection" v-if="showBatch"></el-table-column>
        <el-table-column
          label="序号"
          v-if="showTableIndex"
          type="index"
          :index="indexMethod"
          width="50"
        >
        </el-table-column>
        <el-table-column
          v-for="(column, index) in tableColumns"
          :key="index"
          :prop="column.prop"
          :label="column.label"
          align="center"
          :min-width="column.minWidth"
          :column-key="index.toString()"
          :render-header="renderHeader"
          :sortable="column.sortable"
          :formatter="column.formatter"
        ></el-table-column>
        <el-table-column
          width="120"
          v-if="showOperation"
          header-align="center"
          align="center"
        >
          <template v-slot:header>
              <el-tooltip content="操作" placement="top">
                <span style="cursor: pointer" @click="expandOperation()">
                  <span class="el-icon-s-tools"> </span>
                </span>
              </el-tooltip>
          </template>
          <template v-slot="scope">
            <!-- <el-button v-if="isShowOperation" type="primary" size="medium" icon="el-icon-upload" circle @click="handleUpLoad(scope.$index, scope.row)"></el-button> -->
            <el-button
              type="primary"
              size="medium"
              :icon="ElIconEdit"
              circle
              @click="handleEdit(scope.$index, scope.row)"
            ></el-button>
            <el-button
              type="danger"
              size="medium"
              :icon="ElIconDelete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          fixed="right"
          v-if="showUpDownLoad"
          header-align="center"
          align="center"
          label="下载 / 上传"
        >
          <template v-slot="scope">
            <el-button
              type="primary"
              size="medium"
              :icon="ElIconDownload"
              circle
              @click="handleDownLoad(scope.$index, scope.row)"
            ></el-button>
            <el-button
              type="primary"
              size="medium"
              :icon="ElIconUpload"
              circle
              @click="handleUpLoad(scope.$index, scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页栏-->
    <div class="toolbar" v-if="showPageSize" style="text-align: center">
      <el-pagination
        :page-sizes="[20, 50, 100, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="refreshPageRequest"
        :current-page="pageRequest.pageNum"
        :page-size="pageRequest.pageSize"
        :total="data.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  Edit as ElIconEdit,
  Delete as ElIconDelete,
  Download as ElIconDownload,
  Upload as ElIconUpload,
} from '@element-plus/icons'
import { $on, $off, $once, $emit } from '/utils/gogocodeTransfer'
export default {
  data() {
    return {
      tableColumns: this.columns,
      isShowOperation: false,
      operationCelWidth: 32,
      operationCelFixed: undefined,
      //分页信息
      pageRequest: {
        pageNum: 1,
        pageSize: 20,
      },
      loading: false,
      //列表选中列
      selections: [],
      dragState: {
        start: -9, // 起始元素的 index
        end: -9, // 移动鼠标时所覆盖的元素 index
        dragging: false, // 是否正在拖动
        direction: undefined, // 拖动方向
      },
      ElIconEdit,
      ElIconDelete,
      ElIconDownload,
      ElIconUpload,
    }
  },
  props: {
    columns: Array,
    border: {
      //边框
      type: Boolean,
      default: false,
    },
    data: Object, // 表格分页数据
    size: {
      //尺寸
      type: String,
      default: 'mini',
    },
    align: {
      //表头默认左对齐
      type: String,
      default: 'left',
    },
    stripe: {
      type: Boolean,
      default: false,
    },
    showBatch: {
      //是否显示批量多选
      type: Boolean,
      default: false,
    },
    showOperation: {
      //是否显示操作列
      type: Boolean,
      default: false,
    },
    showUpDownLoad: {
      //是否显示上传下载列
      type: Boolean,
      default: false,
    },
    //是否显示分页
    showPageSize: {
      type: Boolean,
      default: true,
    },
    height: Number,
    tableRowClassName: Object,
    cellStyle: Object,
    showTableIndex: {
      //是否显示序号
      type: Boolean,
      default: true,
    },
    showColTooltip: {
      type: Boolean,
      default: true,
    },
    // 当前行高亮显示
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getRef() {
      return this.$refs.mainTable
    },
    //分页查询
    findPage: function () {
      if (this.showPageSize) {
        this.loading = true
        let callback = (res) => {
          this.loading = false
        }
        $emit(this, 'findPage', {
          pageRequest: this.pageRequest,
          callback: callback,
        })
      }
    },
    handleEdit: function (
      index,
      row //编辑
    ) {
      $emit(this, 'handleEdit', {
        index: index,
        row: row,
      })
    },
    // 删除
    handleDelete: function (index, row) {
      //this.delete(row.id);
      $emit(this, 'handleDelete', {
        index: index,
        row: row,
      })
    },
    //下载
    handleDownLoad: function (index, row) {
      $emit(this, 'handleDownLoad', {
        index: index,
        row: row,
      })
    },
    //上传
    handleUpLoad: function (index, row) {
      $emit(this, 'handleUpLoad', {
        index: index,
        row: row,
      })
    },
    expandOperation: function () {
      if (this.isShowOperation) {
        this.operationCelWidth = 40
        this.operationCelFixed = undefined
      } else {
        this.operationCelWidth = 120
        this.operationCelFixed = 'right'
      }
      this.isShowOperation = !this.isShowOperation
    },
    // 批量删除
    handleBatchDelete: function () {
      let ids = this.selections.map((item) => item.id).toString()
      this.delete(ids)
    },
    delete: function (ids) {
      this.$ui.pages.confirm('确认要删除选中记录吗？').then(() => {
        let params = []
        let idArray = (ids + '').split(',')
        for (var i = 0; i < idArray.length; i++) {
          params.push({
            id: idArray[i],
          })
        }

        $emit(this, 'handleDelete', {
          params: params,
        })
      })
    },
    // 换页刷新
    refreshPageRequest: function (pageNum) {
      this.pageRequest.pageNum = pageNum
      this.findPage()
    },
    handleSizeChange(pageSize) {
      //显示条数切换
      this.pageRequest.pageSize = pageSize
      this.findPage()
    },
    onRowdbClick(row, event) {
      $emit(this, 'onRowdbClick', {
        row: row,
        event: event,
      })
    },
    rightClick(row, column, event) {
      $emit(this, 'rightClick', {
        row: row,
        column: column,
        event: event,
      })
    },
    handleSelectionChange(selections) {
      //多选
      this.selections = selections
      $emit(this, 'selectionChange', {
        selections: selections,
      })
    }, // 选择切换
    handleCurrentChange: function (val, oldVal) {
      $emit(this, 'handleCurrentChange', {
        val: val,
      })
    },
    indexMethod(index) {
      //序号
      return index + 1
    },
    selectRow(row) {
      //选择行
      $emit(this, 'selectRow', row)
    },
    toggleRowsSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.mainTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.mainTable.clearSelection()
      }
    },
    //选中行
    toggleRowSelection(row) {
      if (row) {
        this.$refs.mainTable.toggleRowSelection(row)
      } else {
        this.$refs.mainTable.clearSelection()
      }
    },
    //设置行选中
    setCurrentRow(row) {
      this.$nextTick(() => {
        this.$refs.mainTable.setCurrentRow(row)
      })
    },
    headerCellClassName({ column, columnIndex }) {
      let active =
        columnIndex - 2 === this.dragState.end
          ? `darg_active_${this.dragState.direction}`
          : ''
      let start = columnIndex === this.dragState.start ? `darg_start` : ''
      return `${active} ${start}`
    },
    cellClassName({ column, columnIndex }) {
      return columnIndex === this.dragState.start ? `darg_start` : ''
    },
    renderHeader(createElement, { column }) {
      return createElement(
        'div',
        {
          class: ['thead-cell'],
          on: {
            mousedown: ($event) => {
              this.handleMouseDown($event, column)
            },
            mousemove: ($event) => {
              this.handleMouseMove($event, column)
            },
          },
        },
        [
          // 添加 <a> 用于显示表头 label
          createElement('a', column.label),
          // 添加一个空标签用于显示拖动动画
          createElement('span', {
            class: ['virtual'],
          }),
        ]
      )
    },
    // 按下鼠标开始拖动
    handleMouseDown(e, column) {
      this.dragState.dragging = true
      this.dragState.start = parseInt(column.columnKey)
      // 给拖动时的虚拟容器添加宽高
      let table = document.getElementsByClassName('table-div')[0]
      let virtual = document.getElementsByClassName('virtual')
      for (let item of virtual) {
        item.style.height = table.clientHeight - 1 + 'px'
        item.style.width = item.parentElement.parentElement.clientWidth + 'px'
      }
      document.addEventListener('mouseup', this.handleMouseUp)
    },

    // 鼠标放开结束拖动
    handleMouseUp() {
      this.dragColumn(this.dragState)
      // 初始化拖动状态
      this.dragState = {
        start: -9,
        end: -9,
        dragging: false,
        direction: undefined,
      }
      document.removeEventListener('mouseup', this.handleMouseUp)
    },

    // 拖动中
    handleMouseMove(e, column) {
      if (this.dragState.dragging) {
        let index = parseInt(column.columnKey) // 记录起始列
        if (index - this.dragState.start !== 0) {
          this.dragState.direction =
            index - this.dragState.start < 0 ? 'left' : 'right' // 判断拖动方向
          this.dragState.end = parseInt(column.columnKey)
        } else {
          this.dragState.direction = undefined
        }
      } else {
        return false
      }
    },

    // 拖动易位
    dragColumn({ start, end, direction }) {
      let tempData = []
      let left = direction === 'left'
      let min = left ? end : start - 1
      let max = left ? start + 1 : end
      for (let i = 0; i < this.tableColumns.length; i++) {
        if (i === end) {
          tempData.push(this.tableColumns[start])
        } else if (i > min && i < max) {
          tempData.push(this.tableColumns[left ? i - 1 : i + 1])
        } else {
          tempData.push(this.tableColumns[i])
        }
      }
      this.tableColumns = tempData
    },
  },
  mounted() {
    this.findPage()
  },
  watch: {
    columns(val, oldVal) {
      this.tableColumns = val
    },
  },
  emits: [
    'findPage',
    'handleEdit',
    'handleDelete',
    'handleDownLoad',
    'handleUpLoad',
    'onRowdbClick',
    'rightClick',
    'selectionChange',
    'handleCurrentChange',
    'selectRow',
  ],
}
</script>

<style lang="stylus">
.table-div{.el-table .darg_start {
        background-color: #f3f3f3;
    }

    .el-table th {
        padding: 0;

        .virtual {
            position: fixed;
            display: block;
            width: 0;
            height: 0;
            margin-left: -10px;
            background: none;
            border: none;
        }

        &.darg_active_left {
            .virtual {
                // border-left: 2px dotted #666;
                // z-index: 99;
            }
        }

        &.darg_active_right {
            .virtual {
                // border-right: 2px dotted #666;
                // z-index: 99;
            }
        }
    }

    .thead-cell {
        padding: 0;
        display: inline-flex;
        flex-direction: column;
        align-items: left;
        cursor: pointer;
        overflow: initial;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }

    &.w-table_moving {
        .el-table th .thead-cell {
            cursor: move !important;
        }

        .el-table__fixed {
            cursor: not-allowed;
        }
    }}.red{color:#ff0000}
</style>

<style scoped>
.table-div >>> .el-table .cell {
  line-height: 32px;
  height: 32px;
}
.table-div >>> .el-table th > .cell {
  line-height: 32px;
  height: 32px;
}
</style>
