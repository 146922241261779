//获取cookie
export function getCookie(name) {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  if ((arr = document.cookie.match(reg))) {
    return arr[2]
  } else {
    return null
  }
}

//设置cookie
export function setCookie(name, value, expire) {
  var date = new Date()
  date.setTime(date.getTime() + expire * 1000 * 60)
  document.cookie =
    name +
    '=' +
    escape(value) +
    (expire == null ? '' : ';expires=' + date.toGMTString())
}

//删除cookie
export function delCookie(name) {
  var date = new Date()
  date.setTime(date.getTime() - 1)
  var cookie = getCookie(name)
  if (cookie != null) {
    document.cookie = name + '=' + cookie + ';expires=' + date.toGMTString()
  }
}
