<template>
  <div>
    <div class="table-div">
      <el-table ref="table" border :fit="true" :height="heightNumber" class="table" :class="curTable"
        style="width: 100%" :data="data" :stripe="stripe" :show-overflow-tooltip="showColTooltip" :header-cell-style="{
        background: '#F2F2F2',
        color: '#666',
        height: '35px',
      }" @row-dblclick="rowDblClick" @selection-change="handleSelectionChange" @row-click="rowClick"
        :row-style="rowStyle" :cell-class-name="tdCellClass" :row-class-name="rowClassName"
        :tree-props="customTreeProps" :row-key="customRowKey">
        <el-table-column type="index" width="40" v-if="showIndex">
        </el-table-column>
        <el-table-column type="selection" width="55" v-if="showSelection">
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" v-for="(item, index) in columns" :label="item.label"
          :property="item.prop" :key="index" align="center" :width="item.width" :min-width="item.minWidth"
          :fixed="item.fixed" :sortable="item.sortable">
          <template v-slot="scope">
            <el-link v-if="item.isOperation == 1" style="cursor: pointer; font-size: 12px" type="primary"
              @click="goList(scope.row, scope.column.property)">{{ scope.row[scope.column.property] }}
            </el-link>

            <span v-else-if="item.isOperation == 2 &&
        item.isRed == 1 &&
        scope.row[item.isJudge] == true
        " style="color: green; cursor: text">{{ scope.row[scope.column.property] }}
            </span>
            <span v-else-if="item.isOperation == 2 &&
        item.isRed == 1 &&
        scope.row[item.isJudge] == false
        " style="color: red; cursor: text">{{ scope.row[scope.column.property] }}
            </span>
            <span v-else-if="item.isOperation == 2 &&
        item.isRed == 1 &&
        scope.row[item.isJudge] == null
        ">{{ scope.row[scope.column.property] }}
            </span>

            <span v-else-if="item.isOperation == 3 && !scope.row[item.isJudge]">
              {{ scope.row[scope.column.property] }}
            </span>
            <span v-else-if="item.isOperation == 3 && scope.row[item.isJudge]" style="
                font-weight: bold;
                font-style: italic;
                font-size: 12px;
                cursor: text;
              ">{{ scope.row[scope.column.property] }}
            </span>

            <span v-else-if="item.isOperation == 4 && scope.row[item.isJudge] == 0">
              {{ scope.row[scope.column.property] }}
            </span>
            <span v-else-if="item.isOperation == 4 && scope.row[item.isJudge] == 1"
              style="color: #f7a103; cursor: text">{{ scope.row[scope.column.property] }}
            </span>
            <span v-else-if="item.isOperation == 4 && scope.row[item.isJudge] == 2" style="color: red; cursor: text">{{
        scope.row[scope.column.property] }}
            </span>
            <span v-else-if="item.isOperation == 4">{{ scope.row[scope.column.property] }}
            </span>
            <span v-else-if="item.IsOrNot">{{ !scope.row[scope.column.property] ? '否' : '是' }}
            </span>
            <span v-else-if="item.isOperation == 5" style="cursor: pointer" @dblclick="godbclick(scope.row)">{{
        scope.row[scope.column.property] }}
            </span>
            <img v-else-if="item.isOperation == 6 && !!scope.row[scope.column.property]
        " style="
                cursor: pointer;
                width: 50px;
                height: 50px;
                vertical-align: middle;
              " @click="handlePreview(url + scope.row[scope.column.property])"
              :src="url + scope.row[scope.column.property]" />
            <el-tag v-else-if="item.isOperation == 7"
              :type="item.source[scope.row[scope.column.property]]?.type ? item.source[scope.row[scope.column.property]]?.type : 'info'"
              :size="item.size" :effect="item.effect">{{ scope.row[item.labelProp] }}</el-tag>
            <span v-else-if="item.isOperation == 8">{{ scope.row[item.prop][item.innerProp] }}</span>
            <span v-else>{{ scope.row[scope.column.property] }}</span>
          </template>
        </el-table-column>
        <slot name="custom"></slot>
        <el-table-column align="center" label="操作" :fixed="endFixed" v-if="showEndOperationColumn" :width="endWidth">
          <template v-slot="scope">
            <template v-for="(item, index) in endcolumns" :key="index">
              <el-link v-if="item.isShow && !item.showType" :icon="item.icon" type="primary"
                :disabled="scope.row.IsSystemData" :label="item.label"
                style="cursor: pointer; font-size: 12px; margin-right: 8px" :style="{
        'margin-right': item.styleData,
        'font-size': item.fontSize,
      }" align="center" @click="goEnd(scope.row, item.isOperation)">{{ item.label }}
              </el-link>
              <el-link v-else-if="item.isShow && item.showType == 1 && item.data == scope.row[item.prop]"
                :icon="item.icon" type="primary" :disabled="scope.row.IsSystemData" :label="item.label"
                style="cursor: pointer; font-size: 12px; margin-right: 8px" :style="{
        'margin-right': item.styleData,
        'font-size': item.fontSize,
      }" align="center" @click="goEnd(scope.row, item.isOperation)">{{ item.label }}
              </el-link>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="dialogImgVisible" :append-to-body="true">
      <img style="
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: 0 auto;
        " :src="dialogImageUrl" alt="" />
    </el-dialog>
    <!--分页栏-->
    <div class="pagination" v-if="showPageSize" style="text-align: left">
      <el-pagination background layout="total, sizes,prev, pager, next" @current-change="pageChange"
        @size-change="pageSizeChange" :current-page="pageIndexData" :page-size="pageSizeData"
        :page-sizes="pageInfo.sizeList" :total="pageCountData"></el-pagination>
    </div>
  </div>
</template>

<script>
import { $on, $off, $once, $emit } from '/utils/gogocodeTransfer'
import _config from '@/_config'
export default {
  data() {
    return {
      url: _config.apiBaseUrl,
      dialogImgVisible: false,
      dialogImageUrl: '',
      isShowOperation: false,
      operationCelWidth: 40,
      operationCelFixed: undefined,

      loading: false,
      selections: [], //列表选中列
      dragState: {
        start: -9, // 起始元素的 index
        end: -9, // 移动鼠标时所覆盖的元素 index
        dragging: false, // 是否正在拖动
        direction: undefined, // 拖动方向
      },
      border: true, //this.border
      showOperation: false, //this.showOperation
      //是否显示分页
      showPageSize: this.showPageSizeData, //this.showPageSize
      showTableIndex: true, //this.showTableIndex
      showEndOperationColumn: this.showEndOperation,
      showColTooltip: true, //this.showColTooltip
      highlightCurrentRow: true, //this.highlightCurrentRow

      size: 'mini', //this.size,
      align: '', // this.align,
      stripe: false, //this.stripe,
      showBatch: false, // this.showBatch,
      showUpDownLoad: false, // this.showUpDownLoad,
      heightNumber: this.height,
      tableRowClassName: '', // this.tableRowClassName,
      cellStyle: '', //this.cellStyle,

      //分页数据
      pageInfo: {
        sizeList: this.pageSizeList,
      },
      pageCountData: this.pageCount,
      pageIndexData: this.pageIndex,
      pageSizeData: this.pageSize,
      multipleSelection: [],
      selectionRow: [],
      CtrlDown: false,
      shiftOrAltDown: false,
      resizeTbHeight: this.resizeTableHeight,
      curTable: 'table' + this.guid(),
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    height: Number,
    endcolumns: {
      type: Array,
      default: () => [],
    },
    endWidth: {
      type: Number,
      default: 155,
    },
    showEndOperation: {
      type: Boolean,
      default: false,
    },

    pageIndex: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    pageSizeList: {
      type: Array,
      default: () => [20, 50, 100],
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    showPageSizeData: {
      type: Boolean,
      default: false,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    showIndex: {
      type: Boolean,
      default: false,
    },
    resizeTableHeight: {
      type: Boolean,
      default: true,
    },
    customRowKey: {
      typeof: String,
      default: 'Id',
    },
    customTreeProps: {
      typeof: Object,
      default: () => { },
    },
    tableHeight: {
      typeof: Number,
      default: 251,
    },
    tdCellClass: {
      typeof: String,
      default: '',
    },
    rowHeightVar: {
      typeof: String,
      default: '35px',
    },
    endFixed: {
      typeof: Boolean,
      default: false,
    },
  },
  methods: {
    //图片预览
    handlePreview(url) {
      this.dialogImageUrl = url
      this.dialogImgVisible = true
    },
    //监测表格高度
    resizeDom() {
      if (this.resizeTbHeight) {
        window.onresize = () => {
          //this.$nextTick(()=>{
          this.heightNumber =
            document.documentElement.clientHeight - this.tableHeight
          // })
        }
      }
    },
    //页码改变时
    pageChange(page) {
      this.pageIndexData = page
      $emit(this, 'update:pageIndex', page)
      $emit(this, 'loadOutTableData')
    },
    //页面数据量改变时
    pageSizeChange(size) {
      this.pageSizeData = size
      this.pageIndexData = 1
      $emit(this, 'update:pageIndex', 1)
      $emit(this, 'update:pageSize', size)
      $emit(this, 'loadOutTableData')
    },
    rowDblClick(row) {
      $emit(this, 'rowDblClick', row)
    },
    //
    goList(row, name) {
      $emit(this, 'goList', row)
    },
    //报告
    goReport(row) {
      let routeData = this.$router.resolve({
        name: 'deviceChecksReport',
        params: {
          id: row.Id,
          guid: row.TaskGUID,
          deviceCode: row.DeviceCode,
          taskTypeFlag: row.TaskType,
        },
      })
      window.open(routeData.href, '_blank')
    },
    goEnd(row, typeFlag) {
      $emit(this, 'goEnd', row, typeFlag)
    },
    godbclick(row) {
      //alert(111);
      $emit(this, 'godbclick', row)
    },
    //获取选中的行
    getSelections() {
      return this.$refs.table.selection
    },
    doLayout() {
      this.$refs.table.doLayout()
    },
    handleSelectionChange(val) {
      this.selectionRow = val
    },
    rowClick(row, column, event) {
      let refsElTable = this.$refs.table
      if (this.CtrlDown) {
        refsElTable.toggleRowSelection(row)
        return
      }
      if (this.shiftOrAltDown && this.selectionRow.length > 0) {
        let topAndBottom = getTopAndBottom(
          row,
          this.bottomSelectionRow,
          this.topSelectionRow
        )
        refsElTable.clearSelection()
        for (
          let index = topAndBottom.top;
          index <= topAndBottom.bottom;
          index++
        ) {
          refsElTable.toggleRowSelection(this.data[index], true)
        }
      } else {
        let findRow = this.selectionRow.find((c) => c.rowIndex == row.rowIndex)
        if (findRow && this.selectionRow.length == 1) {
          refsElTable.toggleRowSelection(row, false)
          return
        }
        this.$nextTick(() => {
          refsElTable.clearSelection()
          refsElTable.toggleRowSelection(row)
        })
      }
      $emit(this, 'rowClick', row)
    },
    //生成guid
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    },
    rowStyle({ row, rowIndex }) {
      Object.defineProperty(row, 'rowIndex', {
        value: rowIndex,
        writable: true,
        enumerable: false,
      })
      return { height: this.rowHeightVar }
    },
    rowClassName({ row, rowIndex }) {
      let rowName = '',
        findRow = this.selectionRow.find((c) => c.rowIndex === row.rowIndex)
      if (findRow) {
        rowName = 'current-row '
      }
      return rowName
    },
    keyDown(event) {
      let key = event.keyCode
      if (key == 17) this.CtrlDown = true
      if (key == 16 || key == 18) this.shiftOrAltDown = true
    },
    keyUp(event) {
      let key = event.keyCode
      if (key == 17) this.CtrlDown = false
      if (key == 16 || key == 18) this.shiftOrAltDown = false
    },
  },
  mounted() {
    if (this.resizeTbHeight) {
      this.resizeDom()
    }
    window.addEventListener('keydown', this.keyDown, false)
    window.addEventListener('keyup', this.keyUp, false)
    /*
  this.$nextTick(() => {
    var cells = document
      .getElementsByClassName(this.curTable)[0]
      .getElementsByClassName("el-table__body")[0]
      .getElementsByClassName("cell");
    for (let index = 0; index < cells.length; index++) {
      cells[index].style.height = this.rowHeightVar;
      cells[index].style.lineHeight = this.rowHeightVar;
    }
  });
  */
  },
  created() {
    if (this.resizeTbHeight) {
      this.heightNumber =
        document.documentElement.clientHeight - this.tableHeight
    }
  },
  watch: {
    pageCount(val, oldval) {
      this.pageCountData = val
    },
  },
  computed: {
    bottomSelectionRow() {
      if (this.selectionRow.length == 0) return null
      return this.selectionRow.reduce((start, end) => {
        return start.rowIndex > end.rowIndex ? start : end
      })
    },
    topSelectionRow() {
      if (this.selectionRow.length == 0) return null
      return this.selectionRow.reduce((start, end) => {
        return start.rowIndex < end.rowIndex ? start : end
      })
    },
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyDown)
    window.removeEventListener('keyup', this.keyUp)
  },
  emits: [
    'update:pageIndex',
    'update:pageSize',
    'rowDblClick',
    'goList',
    'goEnd',
    'godbclick',
    'rowClick',
    'loadOutTableData',
  ],
}
function getTopAndBottom(row, bottom, top) {
  let n = row.rowIndex,
    mx = bottom.rowIndex,
    mi = top.rowIndex
  if (n > mx) {
    return {
      top: mi,
      bottom: n,
    }
  } else if (n < mx && n > mi) {
    return {
      top: mi,
      bottom: n,
    }
  } else if (n < mi) {
    return {
      top: n,
      bottom: mx,
    }
  } else if (n == mi || n == mx) {
    return {
      top: mi,
      bottom: mx,
    }
  }
}
</script>

<style>
.el-checkbox__inner {
  width: 13px;
  height: 13px;
}

.table .el-table__expand-icon {
  line-height: 14px !important;
}

.tb-cell .cell {
  height: 50px;
  line-height: 50px !important;
}
</style>
