/***************系统管理API**************************************************************/

import request from '../fetch'

/*****用户管理***************************************************** */

/**
 *获取用户列表
 *
 * @export
 * @returns
 */
export function syncOAUsers(params) {
  return request({
    url: 'api/OASync/Get',
    method: 'get',
    params: params,
  })
}

/**
 *获取用户列表
 *
 * @export
 * @returns
 */
export function getUserList(params) {
  return request({
    url: 'api/db/sys/user/getlist',
    method: 'post',
    data: params,
  })
}

/**
 *获取角色列表   无参数
 *
 * @export
 * @returns
 */
export function getRoleListNoParam() {
  return request({
    url: 'api/db/sys/role/getlistnoparam',
    method: 'post',
  })
}

/**
 *根据用户获取角色列表
 *
 * @export
 * @returns
 */
export function getRoleListByUser(params) {
  return request({
    url: 'api/db/sys/role/getlistbyuser',
    method: 'get',
    params: params,
  })
}

/**
 *分配角色
 *
 * @export
 * @returns
 */
export function setUserRoles(params) {
  return request({
    url: 'api/db/sys/user/saveroles',
    method: 'post',
    data: params,
  })
}

/*****角色管理***************************************************** */

/**
 *获取角色列表
 *
 * @export
 * @returns
 */
export function getRoleList(params) {
  return request({
    url: 'api/db/sys/role/getlist',
    method: 'post',
    data: params,
  })
}

/**
 *保存角色信息
 *
 * @export
 * @returns
 */
export function saveRole(params) {
  return request({
    url: 'api/db/sys/role/save',
    method: 'post',
    data: params,
  })
}

/**
 *删除角色信息
 *
 * @export
 * @returns
 */
export function delRole(params) {
  return request({
    url: 'api/db/sys/role/del',
    method: 'post',
    data: params,
  })
}

/**
 *获取权限列表
 *
 * @export
 * @returns
 */
export function getMenuByRole(params) {
  return request({
    url: 'api/db/sys/menu/getlistbyrole',
    method: 'get',
    params: params,
  })
}

/**
 *通过用户ID获取权限列表
 *
 * @export
 * @returns
 */
export function getMenuByUser(params) {
  return request({
    url: 'api/db/sys/menu/getlistbyuser',
    method: 'get',
    params: params,
  })
}

/**
 *分配菜单权限
 *
 * @export
 * @returns
 */
export function setRoleMenu(params) {
  return request({
    url: 'api/db/sys/role/savemenus',
    method: 'post',
    data: params,
  })
}

/*****菜单管理***************************************************** */
/**
 *获取菜单列表
 *
 * @export
 * @returns
 */
export function getMenuList(showLoading, data) {
  return request({
    url: 'api/db/sys/menu/getlist',
    method: 'post',
    params: { isShowRequest: data, ShowLoading: showLoading },
  })
}

/**
 *保存菜单信息
 *
 * @export
 * @returns
 */
export function saveMenu(params) {
  return request({
    url: 'api/db/sys/menu/save',
    method: 'post',
    data: params,
  })
}

/**
 *删除菜单信息
 *
 * @export
 * @returns
 */
export function delMenu(params) {
  return request({
    url: 'api/db/sys/menu/del',
    method: 'post',
    data: params,
  })
}

/**
 *登录
 *
 * @export
 * @returns
 */
export function login(params) {
  return request({
    url: 'api/db/sys/user/login',
    method: 'post',
    data: params,
  })
}
