/***************基础数据模块API**************************************************************/

import request from '../fetch'

/**
 * 新增订单匹配规则
 * @param {*} data
 * @returns
 */
export function addOrderSetting(data) {
  return request({
    url: 'api/Batch/AddOrderSetting',
    method: 'post',
    data: data,
  })
}

/**
 * 更新订单匹配规则
 * @param {*} data
 * @returns
 */
export function updateOrderSetting(data) {
  return request({
    url: 'api/Batch/UpdateOrderSetting',
    method: 'post',
    data: data,
  })
}

/**
 * 删除订单匹配规则
 * @param {*} data
 * @returns
 */
export function deleteOrderSetting(data) {
  return request({
    url: 'api/Batch/DeleteOrderSetting',
    method: 'get',
    params: data,
  })
}

/**
 * 匹配订单规则
 * @param {*} data
 * @returns
 */
export function matchOrderSetting(data) {
  return request({
    url: 'api/Batch/TemplateColor',
    method: 'post',
    data: data,
  })
}

/**
 * 获取生产订单列表
 * @param {*} data
 * @returns
 */
export function getOrderList(data) {
  return request({
    url: 'api/Batch/GetOrderList',
    method: 'post',
    data: data,
  })
}

/**
 * 获取订单匹配规则列表
 * @param {*} data
 * @returns
 */
export function getOrderSettingList(data) {
  return request({
    url: 'api/Batch/GetOrderSettingList',
    method: 'get',
    params: data,
  })
}

/**
 * 获取设备类型下拉列表
 * @param {*} data
 * @returns
 */
export function getProductModeList(data) {
  return request({
    url: 'api/Batch/getProductModeList',
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品分类与设备树数据列表
 * @param {*} data
 * @returns
 */
export function getCategoryAndProductList(data) {
  return request({
    url: 'api/Batch/GetCategoryAndProductList',
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品分类与设备树数据列表
 * @param {*} data
 * @returns
 */
export function getProductBatchList(data) {
  return request({
    url: 'api/Batch/GetProductBatchList',
    method: 'get',
    params: data,
  })
}

/**
 * 获取产品分类与设备树数据列表
 * @param {*} data
 * @returns
 */
export function getProductBatchDetailList(data) {
  return request({
    url: 'api/Batch/GetProductBatchDetailList',
    method: 'get',
    params: data,
  })
}

/**
 * 获取编码规则实例分段信息
 * @param {*} data
 * @returns
 */
export function getProductInstanceListByProductId(data) {
  return request({
    url: 'api/Batch/GetProductInstanceListByProductId',
    method: 'get',
    params: data,
  })
}

/**
 * 获取最大批次号
 * @param {*} data
 * @returns
 */
export function getMaxBatchNumber(data) {
  return request({
    url: 'api/Batch/GetMaxBatchNumber',
    method: 'post',
    data: data,
  })
}

/**
 * 创建批次
 * @param {*} data
 * @returns
 */
export function createBatch(data) {
  return request({
    url: 'api/Batch/CreateBatch',
    method: 'post',
    data: data,
  })
}

/**
 * 批量创建组件批次信息
 * @param {*} data
 * @returns
 */
export function createBatchComponent(data) {
  return request({
    url: 'api/Batch/CreateBatchComponent',
    method: 'post',
    data: data,
  })
}

/**
 * 删除批次
 * @param {*} data
 * @returns
 */
export function deleteBatch(data) {
  return request({
    url: 'api/Batch/DeleteBatch',
    method: 'get',
    params: data,
  })
}

/**
 * 获取组件批次创建信息
 * @param {*} data
 * @returns
 */
export function getAssemblyList(data) {
  return request({
    url: 'api/Batch/GetAssemblyList',
    method: 'post',
    data: data,
  })
}

/**
 * 根据SN获取批次详情
 * @param {*} data
 * @returns
 */
export function getProductBatchDetailListBySN(data) {
  return request({
    url: 'api/Batch/GetProductBatchDetailListBySN',
    method: 'get',
    params: { sn: data },
  })
}

/**
 * 获取打印地址
 * @param {*} data
 * @returns
 */
export function getPrintURL(data) {
  return request({
    url: 'api/Batch/GetPrintURL',
    method: 'get',
    params: data,
  })
}

/**
 * 更改打印状态（时间）
 * @param {*} data
 * @returns
 */
export function updatePrintState(data) {
  return request({
    url: 'api/Batch/UpdatePrintState',
    method: 'post',
    data: data,
  })
}

/**
 * 打印
 * @param {*} data
 * @returns
 */
export function print(url, data) {
  return request({
    url: url,
    method: 'post',
    data: data,
  })
}
